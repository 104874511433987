import { Paper } from "@mui/material";
import DocTestFile from "../components/doc/docTest/DocTestFile";
import styled from "styled-components";
import { colors, mainColors } from "../constants";
import { useState } from "react";
import DocTestResult from "../components/doc/docTest/DocTestResult";

const DocTest = () => {
  const [documentFile, setDocumentFile] = useState({
    document: {},
    evaluation: {},
  });
  const [isTesting, setIsTesting] = useState(false);

  return (
    <>
      <DocTestContainer>
        <DocTestFileListContainer>
          <Paper elevation={6} sx={{ height: "100%", position: "relative" }}>
            <DocTestFile
              setDocumentFile={setDocumentFile}
              isTesting={isTesting}
              setIsTesting={setIsTesting}
            />
          </Paper>
        </DocTestFileListContainer>
        <DocTestDevider />
        <DocTestResultContainer>
          <DocTestResult documentFile={documentFile} isTesting={isTesting} />
        </DocTestResultContainer>
      </DocTestContainer>
    </>
  );
};

const DocTestContainer = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${mainColors.BACKGROUND};
`;

const DocTestFileListContainer = styled.div`
  margin: 35px 50px 20px 50px;
  position: relative;
  width: 40%;
  height: 100%;
  background-color: ${colors.WHITE};
`;

const DocTestDevider = styled.div`
  width: 1px;
  height: 80vh;
  background-color: ${colors.GRAY_200};
  margin-top: 20px;
  box-shadow: 0 7px 10px rgba(0, 0, 0, 0.2);
  transform: translateX(2vw);
`;

const DocTestResultContainer = styled.div`
  width: 60%;
  height: 85vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export default DocTest;
