import React from "react";
import styled from "styled-components";

const RadioButtonGroup = ({ options, selectedValue, onChange }) => {
  return (
    <RadioButtonGroupContainer>
      {options.map((option) => (
        <RadioButtonWrapper key={option.value}>
          <RadioButton
            type="radio"
            name="object"
            value={option.value}
            checked={selectedValue === option.value}
            onChange={onChange}
          />
          {option.label}
        </RadioButtonWrapper>
      ))}
    </RadioButtonGroupContainer>
  );
};

const RadioButtonGroupContainer = styled.div`
  margin: 0px 10px 5px;
  display: flex;
  gap: 20px;
  overflow: hidden;
`;

const RadioButtonWrapper = styled.label`
  display: flex;
  align-items: center;
`;

const RadioButton = styled.input`
  margin-right: 10px;
`;

export default RadioButtonGroup;
