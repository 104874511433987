import React from "react";
import styled from "styled-components";
import { blockColors, colors } from "../../constants";

const HistoryTable = () => {
  const historyData = [
    { content: "X1 셀 데이터 변경", author: "박보검", date: "2024.07.19" },
    { content: "X2 셀 데이터 변경", author: "홍길동", date: "2024.07.17" },
    { content: "X3 셀 데이터 변경", author: "이미연", date: "2024.07.14" },
    { content: "X4 셀 데이터 변경", author: "이지은", date: "2024.07.12" },
  ];

  return (
    <TableWrapper>
      <TableHeaderWrapper>
        <TableHeader flex={2} align={"center"}>
          내용
        </TableHeader>
        <TableHeader flex={1} align={"center"}>
          작성자
        </TableHeader>
        <TableHeader flex={1} align={"center"}>
          작성일
        </TableHeader>
      </TableHeaderWrapper>
      {historyData.map((item, index) => (
        <TableRow key={index}>
          <TableData flex={2}>{item.content}</TableData>
          <TableData flex={1} align={"center"}>
            {item.author}
          </TableData>
          <TableData flex={1} align={"center"}>
            {item.date}
          </TableData>
        </TableRow>
      ))}
    </TableWrapper>
  );
};

const TableWrapper = styled.div`
  width: 98%;
  height: auto;
`;

const TableHeaderWrapper = styled.div`
  display: flex;
  background-color: ${colors.WHITE};
`;

const TableHeader = styled.div`
  flex: ${(props) => props.flex || 1};
  padding: 10px;
  text-align: ${({ align }) => (align ? align : "left")};
  color: ${colors.BLACK};
  font-weight: bold;
  position: sticky;
  top: 0;
  z-index: 1;
  border-bottom: 2px solid ${colors.GRAY_200};
`;

const TableRow = styled.div`
  display: flex;
  &:nth-child(even) {
    background-color: ${colors.GRAY_100};
  }
`;

const TableData = styled.div`
  flex: ${(props) => props.flex || 1};
  padding: 10px;
  text-align: ${({ align }) => (align ? align : "left")};
  color: ${colors.GRAY_700};
  border-bottom: 1px solid ${blockColors.BORDER_GRAY};
`;

export default HistoryTable;
