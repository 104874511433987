import React from "react";
import Tooltip from "@mui/material/Tooltip";
import styled from "styled-components";

const CommonTooltip = ({ title, children, ...props }) => {
  return (
    <Tooltip title={title} placement="bottom" arrow {...props}>
      <TooltipText>{children}</TooltipText>
    </Tooltip>
  );
};

const TooltipText = styled.span`
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  max-width: 100%;
`;

export default CommonTooltip;
