import React from "react";
import styled from "styled-components";
import { blockColors, fontSize, mainColors, colors } from "../../../constants";
import { Button, CircularProgress } from "@mui/material";
import { GridMenuIcon } from "@mui/x-data-grid";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";

const DocTestResult = ({ documentFile, isTesting }) => {
  const formatToPercentage = (value) => {
    return (parseFloat(value) * 100).toFixed(1) + "%";
  };

  return (
    <>
      <DocTestResultHeader>
        <HeaderContainer>
          <KeyboardArrowLeftIcon sx={{ color: "white", fontSize: "38px" }} />
          <DocTestResultHeaderLabel>
            리스크 문장추출 테스트
          </DocTestResultHeaderLabel>
          <GridMenuIcon sx={{ color: "white", fontSize: "38px" }} />
        </HeaderContainer>
      </DocTestResultHeader>
      <DocTestResultContents>
        <DocTestContentsWrapper>
          <RiskTableHeaderLabel>
            {"< "}독소조항 추출결과 확인 및 정확도 측정{" >"}
          </RiskTableHeaderLabel>
          <RiskTable>
            <thead>
              <RiskTableRow>
                <RiskTableHeader>구분</RiskTableHeader>
                <RiskTableHeader>정확도</RiskTableHeader>
              </RiskTableRow>
            </thead>
            <tbody>
              <RiskTableRow>
                <RiskTableData>통합 문서 독소조항 추출</RiskTableData>
                <RiskTableData>
                  <strong>
                    {isTesting ? (
                      <CircularProgress
                        size={16}
                        style={{ color: colors.GRAY_700 }}
                      />
                    ) : documentFile.evaluation?.[2]?.Value !== undefined ? (
                      formatToPercentage(documentFile.evaluation[2].Value)
                    ) : (
                      "-"
                    )}
                  </strong>
                </RiskTableData>
              </RiskTableRow>
            </tbody>
          </RiskTable>
          <ContentsDevider />
          <DetailTableContainer>
            <DetailTableHeaderLabel>
              {"< "}독소조항 추출결과 확인 상세 측정 지수{" >"}
            </DetailTableHeaderLabel>
            <DetailTable>
              <tbody>
                <DetailTableRow>
                  <DetailTableData>Accurracy</DetailTableData>
                  <DetailTableData>
                    {isTesting ? (
                      <CircularProgress
                        size={12}
                        style={{ color: colors.GRAY_700 }}
                      />
                    ) : documentFile.evaluation?.[0]?.Value !== undefined ? (
                      formatToPercentage(documentFile.evaluation[0].Value)
                    ) : (
                      "-"
                    )}
                  </DetailTableData>
                </DetailTableRow>
                <DetailTableRow>
                  <DetailTableData>Precision</DetailTableData>
                  <DetailTableData>
                    {isTesting ? (
                      <CircularProgress
                        size={12}
                        style={{ color: colors.GRAY_700 }}
                      />
                    ) : documentFile.evaluation?.[1]?.Value !== undefined ? (
                      formatToPercentage(documentFile.evaluation[1].Value)
                    ) : (
                      "-"
                    )}
                  </DetailTableData>
                </DetailTableRow>
                <DetailTableRow>
                  <DetailTableData>Recall</DetailTableData>
                  <DetailTableData>
                    {isTesting ? (
                      <CircularProgress
                        size={12}
                        style={{ color: colors.GRAY_700 }}
                      />
                    ) : documentFile.evaluation?.[2]?.Value !== undefined ? (
                      formatToPercentage(documentFile.evaluation[2].Value)
                    ) : (
                      "-"
                    )}
                  </DetailTableData>
                </DetailTableRow>
                <DetailTableRow>
                  <DetailTableData>F1 Score</DetailTableData>
                  <DetailTableData>
                    {isTesting ? (
                      <CircularProgress
                        size={12}
                        style={{ color: colors.GRAY_700 }}
                      />
                    ) : documentFile.evaluation?.[3]?.Value !== undefined ? (
                      formatToPercentage(documentFile.evaluation[3].Value)
                    ) : (
                      "-"
                    )}
                  </DetailTableData>
                </DetailTableRow>
              </tbody>
            </DetailTable>
          </DetailTableContainer>
          <ContentsDevider />
          <DocTestDocumentHeader>
            독소조항 추출결과 원문보기
          </DocTestDocumentHeader>
          <DocTestDocumentButtonContainer>
            <Button
              size="large"
              variant="contained"
              sx={{
                px: 8,
                mr: 2,
                whiteSpace: "nowrap",
                backgroundColor: mainColors.TEST_BLUE,
                "&:hover": {
                  opacity: 0.9,
                  backgroundColor: mainColors.TEST_BLUE,
                },
              }}
              disabled={!documentFile.document}
            >
              원문 확인
            </Button>
          </DocTestDocumentButtonContainer>
        </DocTestContentsWrapper>
      </DocTestResultContents>
    </>
  );
};

const CONTAINER_WIDTH = "40vw";
const CONTAINER_HEIGHT = "70vh";
const RISK_TABLE_BORDER = blockColors.BORDER_GRAY;
const DETAIL_TABLE_BORDER = "rgba(0, 0, 0, 0.1)";

const DocTestResultHeader = styled.div`
  width: ${CONTAINER_WIDTH};
  height: 76px;
  background-color: ${mainColors.TEST_BLUE};
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  padding: 0 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const DocTestResultHeaderLabel = styled.label`
  font-size: ${fontSize.LARGE};
  color: ${colors.WHITE};
  font-weight: bold;
  margin: 0 20px;
`;

const DocTestResultContents = styled.div`
  width: ${CONTAINER_WIDTH};
  height: ${CONTAINER_HEIGHT};
  background-color: ${colors.WHITE};
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  overflow-y: auto;
`;

const DocTestContentsWrapper = styled.div`
  margin: 0vw 1vw 0vw 1vw;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const ContentsDevider = styled.div`
  background-color: ${colors.GRAY_200};
  width: 95%;
  height: 1px;
  margin-top: 25px;
`;

const RiskTable = styled.table`
  margin-top: 5px;
  width: 88%;
  height: 100px;
  border-collapse: collapse;
  border: 1px solid ${RISK_TABLE_BORDER};
`;

const RiskTableHeaderLabel = styled.label`
  margin-top: 15px;
  color: ${colors.GRAY_700};
  font-size: 14px;
`;

const RiskTableHeader = styled.th`
  padding: 10px;
  background-color: ${colors.LIGHT_BLUE};
  border-right: 1px solid ${RISK_TABLE_BORDER};
  height: 50px;
  &:last-child {
    border-right: none;
  }
`;

const RiskTableRow = styled.tr`
  border-bottom: 1px solid ${RISK_TABLE_BORDER};
`;

const RiskTableData = styled.td`
  padding: 10px;
  text-align: center;
  border-right: 1px solid ${RISK_TABLE_BORDER};
  &:last-child {
    border-right: none;
  }
`;

const DetailTableContainer = styled.div`
  margin-top: 20px;
  position: relative;
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const DetailTableHeaderLabel = styled.label`
  color: ${colors.GRAY_700};
  font-size: 14px;
`;

const DetailTable = styled.table`
  margin-top: 5px;
  width: 100%;
  border-collapse: collapse;
  border: 1px solid ${DETAIL_TABLE_BORDER};
`;

const DetailTableRow = styled.tr`
  border-bottom: 1px solid ${DETAIL_TABLE_BORDER};
`;

const DetailTableData = styled.td`
  padding: 3px;
  text-align: center;
  border: 1px solid ${DETAIL_TABLE_BORDER};
  font-size: 14px;
`;

const DocTestDocumentHeader = styled.label`
  margin-top: 20px;
  color: ${colors.GRAY_700};
  font-size: ${fontSize.LARGE};
  font-weight: bold;
`;

const DocTestDocumentButtonContainer = styled.div`
  margin-top: 3vh;
`;

export default DocTestResult;
