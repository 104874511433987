import FolderIcon from "@mui/icons-material/Folder";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import { useDispatch, useSelector } from "react-redux";
import { DataGrid } from "@mui/x-data-grid";
import { setDocTestCloudAlert } from "../../../actions/module/docTestAction";
import createAxiosConfig from "../../../services/AxiosConfig";

const DocTestFile = ({ setDocumentFile, isTesting, setIsTesting }) => {
  const dispatch = useDispatch();
  const axiosConfig = createAxiosConfig(dispatch);

  const [fileList, setFileList] = useState([]);
  const [selectCheckbox, setSelectCheckbox] = useState([]);
  const [selectFileName, setSelectFileName] = useState([]);

  const docTestCloudAlert = useSelector((state) => state.docTestCloudAlert);

  const theme = createTheme({
    components: {
      MuiDataGrid: {
        styleOverrides: {
          root: {
            "& .MuiDataGrid-cell:focus": {
              outline: "none",
            },
            "& .MuiDataGrid-columnHeader:focus": {
              outline: "none",
            },
            "& .MuiDataGrid-row:hover": {
              backgroundColor: "#F5F5F5",
            },
          },
        },
      },
    },
  });

  const rows = fileList
    .filter((file) => ["json"].includes(file.extension.toLowerCase()))
    .map((file, index) => ({
      id: index,
      fileName: file.fileName,
      extension: file.extension,
      fileSize: file.fileSize,
      registeredAt: file.registeredAt,
      selectFileName: file.selectFileName,
    }));
  const columns = [
    {
      field: "fileName",
      headerName: "이름",
      width: 280,
      renderCell: (params) => {
        const isFolder = params.row.extension === "폴더";
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            {isFolder ? (
              <FolderIcon style={{ marginRight: "10px", color: "#666666" }} />
            ) : (
              <InsertDriveFileOutlinedIcon style={{ marginRight: "10px" }} />
            )}
            {params.value}
          </div>
        );
      },
    },
    { field: "registeredAt", headerName: "생성 날짜", width: 240 },
    { field: "fileSize", headerName: "파일 크기", width: 100 },
  ];

  const handleSelectCheckbox = (newSelectionModel, rows) => {
    if (newSelectionModel.length > 1) {
      alert("1개 파일만 선택해 주세요");
      setSelectCheckbox(newSelectionModel.slice(0, 1));
      return;
    }
    setSelectCheckbox(newSelectionModel);

    const selectFileName = newSelectionModel.map((id) => rows[id].fileName);
    setSelectFileName(selectFileName[0]);
  };

  const handleDocTest = async () => {
    if (!selectFileName) {
      alert("파일 이름을 선택해주세요.");

      return;
    }

    try {
      setIsTesting(true);
      const response = await axiosConfig.get(
        `/api/v1/doc/analysis-test?filename=${selectFileName}`
      );

      if (response.status === 200) {
        if (response.data.resultCode === 200) {
          setSelectCheckbox([]);
          setIsTesting(false);
          setDocumentFile(response.data.result);
        } else if (response.data.resultCode === 202) {
          listenToSse(selectFileName);
        }
      } else {
        alert("테스트 진행에 실패했습니다.");
      }
    } catch (error) {
      if (
        error.response &&
        (error.response.status === 500 || error.response.status === 404)
      ) {
        alert("테스트 진행에 실패했습니다.");
      } else {
        alert("알 수 없는 오류가 발생했습니다.");
      }
    }
  };

  const listenToSse = (filename) => {
    const eventSource = new EventSource(
      `/api/v1/doc/analysis-event-test?filename=${filename}`
    );

    eventSource.addEventListener("event-wait", function (event) {
      try {
        console.log(event.data);
      } catch (error) {
        alert("분석 대기 중 에러가 발생했습니다.");
        eventSource.close();
      }
    });

    eventSource.addEventListener("event-result", function (event) {
      try {
        setSelectCheckbox([]);
        setIsTesting(false);
        setDocumentFile(JSON.parse(event.data));
      } catch (error) {
        alert("분석 결과에서 에러가 발생했습니다.");
      } finally {
        eventSource.close();
      }
    });

    eventSource.onerror = (error) => {
      alert("대용량 분석 중 에러가 발생했습니다.");
      eventSource.close();
    };
  };

  const filesList = async () => {
    const response = await axiosConfig.get(
      `/api/v1/file?currentPath=doc_test/`
    );

    if (response.status === 200) {
      const modifiedResult = response.data.result.map((file) => {
        if (file.fileName.includes("$")) {
          const [name] = file.fileName.split("$");
          const extensionIndex = file.fileName.indexOf("-result.json");
          const extension =
            extensionIndex !== -1 ? file.fileName.slice(extensionIndex) : "";
          file.fileName = `${name}${extension}`;
        }
        return file;
      });

      setFileList(modifiedResult);
      setSelectCheckbox([]);
    }
  };

  useEffect(() => {
    filesList();
  }, []);

  return (
    <>
      <Box
        sx={{
          py: 1,
          pl: 2,
          display: "flex",
          justifyContent: "end",
          overflowX: "auto",
        }}
      >
        <Button
          size="small"
          variant="contained"
          endIcon={!isTesting && <SendIcon />}
          sx={{ px: 2, mr: 2, whiteSpace: "nowrap" }}
          onClick={!isTesting ? handleDocTest : undefined}
          disabled={isTesting}
        >
          {isTesting ? "테스트 중..." : "테스트 실행"}
        </Button>
      </Box>
      <ThemeProvider theme={theme}>
        <DataGrid
          sx={{
            width: "100%",
            height: "calc(100vh - 220px)",
            "&:hover": { cursor: "default" },
          }}
          rows={rows}
          columns={columns}
          checkboxSelection
          disableColumnMenu
          disableAutoFocus
          disableVirtualization
          disableRowSelectionOnClick
          hideFooter
          onRowSelectionModelChange={(newSelectionModel) =>
            handleSelectCheckbox(newSelectionModel, rows)
          }
          rowSelectionModel={selectCheckbox}
        />
      </ThemeProvider>

      <Dialog
        open={docTestCloudAlert}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            dispatch(setDocTestCloudAlert(false));
          }
        }}
      >
        <DialogTitle>파일 설정 오류</DialogTitle>
        <DialogContent>
          <DialogContentText>파일을 선택해주세요.</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => dispatch(setDocTestCloudAlert(false))}>
            닫기
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DocTestFile;
