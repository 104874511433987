import createAxiosConfig from '../AxiosConfig';
import { setAdministratorInfo, setPasswordResetAlert, setUserDeleteAlert, updateAdministratorInfo } from '../../actions';
import { useDispatch } from 'react-redux';

export default function AdministratorListAPI() {

  const dispatch = useDispatch();
  const axiosConfig = createAxiosConfig(dispatch);

  const administratorInfo = async () => {
    try {
      const response= await axiosConfig.get('/admin/v1/user')

      if(response.status === 200) {
        dispatch(setAdministratorInfo(response.data.result));
      }
    } catch(error) {
      console.error(error);
    }
  };

  const passwordReset = async (id) => {
    try {
      const response = await axiosConfig.put('/admin/v1/user/' + id);

      if (response.status === 200) {
        dispatch(setPasswordResetAlert(false));
        alert("초기화가 완료되었습니다.");
      } else {
        alert("초기화에 실패하였습니다.");
      }
    } catch(error) {
      console.error(error);
    }
  };

  const userDelete = async (id) => {
    try {
      const response = await axiosConfig.delete('/admin/v1/user/' + id);

      if (response.status === 200) {
        dispatch(setUserDeleteAlert(false));
        alert("탈퇴처리가 완료되었습니다.\n회원 정보 탭을 눌러 최신화해주세요.");
        // window.location.replace("/module/admin");
      } else {
        alert("탈퇴처리에 실패하였습니다.");
      }
    } catch(error) {
      console.error(error);
    }
  };


  const handleGradeType = async (id, gradeType) => {
    try {
      const url = `/admin/v1/user/${id}/role`;
      
      const requestBody = { gradeType };
  
      const response = await axiosConfig.put(url, requestBody);

      if (response.status === 200) {
        const updatedInfo = response.data.result;
        dispatch(updateAdministratorInfo(updatedInfo));
        alert("처리가 완료되었습니다.");
      } else {
        alert("처리에 실패하였습니다.");
      }

    } catch (error) {
      console.error(error);
    }
  };
  
  const userClassRoleBasic = async (id) => {
    await handleGradeType(id, 'BASIC');
  };
  
  const userClassRoleStandard = async (id) => {
    await handleGradeType(id, 'STANDARD');
  };
  
  const userClassRolePremium = async (id) => {
    await handleGradeType(id, 'PREMIUM');
  };
  

  const handleAdminRole = async (id, role, method) => {
    try {
      const url = `/admin/v1/user/${id}/role-${role}`;
      const response = method === 'PUT'
        ? await axiosConfig.put(url)
        : await axiosConfig.delete(url);
  
      if (response.status === 200) {
        const updatedInfo = response.data.result;
        dispatch(updateAdministratorInfo(updatedInfo));
        // await administratorInfo();
        alert("처리가 완료되었습니다.");
      } else {
        alert("처리에 실패하였습니다.");
      }
    } catch (error) {
      console.error(error);
    }
  };
  
  const userClassAdmin = async (id) => {
    await handleAdminRole(id, 'admin', 'PUT');
  };

  const userClassUser = async (id) => {
    await handleAdminRole(id, 'admin', 'DELETE');
  };
  

  const adminUserData = async (id) => {
    try {
      const response = await axiosConfig.get(`/admin/v1/file/${id}/volumes`);
  
      if (response.status === 200) {
  
        const { result } = response.data; // 응답에서 result 객체 추출
  
        const addInfo = {
          id, // 기존 관리자 ID
          drawVolume: result.drawVolume,
          sheetVolume: result.sheetVolume,
          docVolume: result.docVolume,
          parserCount: result.parsingCount,
          analysisCount: result.extractCount, // extractCount에 대한 변수 이름 변경
          username: result.username,
          name: result.name,
          organization: result.organization,
          job: result.job,
          registeredAt: result.registeredAt,
          updatedAt: result.updatedAt,
          deletedAt: result.deletedAt,
          roles: result.roles,
        };
  
        // 상태 업데이트를 위한 액션 디스패치
        // dispatch(addAdministratorInfo(addInfo));
        dispatch(updateAdministratorInfo(addInfo)); 
  
        return addInfo;
      }
    } catch (error) {
      console.error(error);
    }
  };

  return { administratorInfo, passwordReset, userDelete, userClassAdmin, userClassUser, adminUserData, userClassRoleBasic, userClassRoleStandard, userClassRolePremium}
}