import React, { useState } from "react";
import styled from "styled-components";
import { colors, fontSize, mainColors } from "../../constants";
import { GridMenuIcon } from "@mui/x-data-grid";
import ObjectData from "./ObjectData";
import { Tab, Tabs } from "@mui/material";

const SheetInfoDataForm = ({
  isFixed,
  selectedObj,
  selectedTab,
  setSelectedTab,
}) => {
  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <Container id="id=object-data-container" isFixed={isFixed}>
      <SheetInfoDataFormHeader>
        <SheetInfoDataFormHeaderLabel>
          시트 분석 데이터
        </SheetInfoDataFormHeaderLabel>
        <IconWrapper>
          <GridMenuIcon sx={{ color: "white", fontSize: "32px" }} />
        </IconWrapper>
      </SheetInfoDataFormHeader>
      <ContensContainer>
        <StyledTabs value={selectedTab} onChange={handleChange} centered>
          <StyledTab label="객체 분석" />
          <StyledTab label="관계 분석" />
        </StyledTabs>
        <ContentsWrap>
          <TabContent>
            {selectedTab === 0 && <ObjectData selectedObj={selectedObj} />}
            {selectedTab === 1 && (
              <ObjectSelectContainer>
                <ObjectDataHeaderContainer>
                  <HeaderLabel />
                  <ObjectDataHeader>의미 관계 트리</ObjectDataHeader>
                </ObjectDataHeaderContainer>
                <div id="grid-item2" className="tree-visualization"></div>
              </ObjectSelectContainer>
            )}
          </TabContent>
        </ContentsWrap>
      </ContensContainer>
    </Container>
  );
};

const Container = styled.div`
  position: ${({ isFixed }) => (isFixed ? "fixed" : "relative")};
  width: 30%;
  margin-right: 20px;
  padding-left: ${({ isFixed }) => (isFixed ? `20px` : 0)};
  bottom: 0;
  right: 0;
  z-index: 1;
  transition: top 0.3s ease, position 0.3s ease-out;
`;

const SheetInfoDataFormHeader = styled.div`
  height: 60px;
  background-color: ${mainColors.TEST_BLUE};
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  box-shadow: 2px 2px 9px rgba(0, 0, 0, 0.2);
`;

const SheetInfoDataFormHeaderLabel = styled.label`
  color: ${colors.WHITE};
  font-size: 20px;
  font-weight: bold;
`;

const IconWrapper = styled.div`
  position: absolute;
  right: 16px;
  transform: translateY(6%);
`;

const ContensContainer = styled.div`
  height: 85vh;
  background-color: ${colors.WHITE};
  box-shadow: 2px 2px 9px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
`;

const StyledTabs = styled(Tabs)`
  border-bottom: 1px solid ${colors.GRAY_200};
  .MuiTabs-indicator {
    background-color: ${mainColors.TEST_BLUE};
    height: 3px;
  }
`;

const ContentsWrap = styled.div`
  overflow-y: auto;
  display: flex;
  justify-content: center;
  flex-grow: 1;
`;

const StyledTab = styled(Tab)`
  &.Mui-selected {
    color: ${mainColors.TEST_BLUE};
    font-weight: bold;
    border-top: ${mainColors.TEST_BLUE};
  }
`;

const TabContent = styled.div`
  margin-left: 1vw;
  margin-right: 1vw;
  margin-top: 20px;
  width: 100%;
  font-size: 16px;
`;

const ObjectSelectContainer = styled.div`
  padding: 5px 10px 25px;
  border: 2px solid ${colors.GRAY_200};
  border-radius: 10px;
  background-color: ${colors.WHITE};
  margin-bottom: 10px;
`;

const ObjectDataHeaderContainer = styled.div`
  margin: 10px;
  gap: 6px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const HeaderLabel = styled.div`
  background-color: ${mainColors.POINT_BLUE};
  height: ${parseFloat(fontSize.MEDIUM) + 4 + "px"};
  width: 6px;
  transform: translateY(1px);
`;

const ObjectDataHeader = styled.label`
  font-size: ${fontSize.MEDIUM};
  font-weight: bold;
`;

export default SheetInfoDataForm;
