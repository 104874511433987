export const SET_ADMINISTRATOR_INFO = 'SET_ADMINISTRATOR_INFO';
export const UPDATE_ADMINISTRATOR_INFO = 'UPDATE_ADMINISTRATOR_INFO';
export const SET_ADMINISTRATOR_PAGE = 'SET_ADMINISTRATOR_PAGE';
export const ADD_ADMINISTRATOR_INFO = 'ADD_ADMINISTRATOR_INFO';

export function setAdministratorInfo(administratorInfo) {
  return {
    type: SET_ADMINISTRATOR_INFO,
    administratorInfo,
  };
}

export function updateAdministratorInfo(updatedInfo) {
  return {
    type: UPDATE_ADMINISTRATOR_INFO,
    updatedInfo,
  };
}

export function addAdministratorInfo(newInfo) {
  return {
    type: ADD_ADMINISTRATOR_INFO,
    newInfo,
  };
}

export function setAdministratorPage(administratorPage) {
  return {
    type: SET_ADMINISTRATOR_PAGE,
    administratorPage,
  };
}