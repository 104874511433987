import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Avatar } from '@mui/material';
import { blue, grey, red } from '@mui/material/colors';

export default function MyPageGradeInfo() {
  return (
    <TableContainer sx={{ margin: 6, mt:0, mb:0, width:'90%' }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="center"></TableCell>
            <TableCell align="center">
              <Avatar sx={{ margin: '0 auto', bgcolor: grey[500], width: 90, height: 90, fontSize: '3rem' }}> B </Avatar>
              <Typography sx={{ pt:1, fontWeight: 'bold', fontSize: '1.2rem' }}>BASIC</Typography>
            </TableCell>
            <TableCell align="center">
              <Avatar sx={{ margin: '0 auto', bgcolor: blue[300], width: 90, height: 90, fontSize: '3rem' }}> S </Avatar>
              <Typography sx={{ pt:1, fontWeight: 'bold', fontSize: '1.2rem' }}>STANDARD</Typography>
            </TableCell>
            <TableCell align="center">
              <Avatar sx={{ margin: '0 auto', bgcolor: red[300], width: 90, height: 90, fontSize: '3rem' }}> P </Avatar>
              <Typography sx={{ pt:1, fontWeight: 'bold', fontSize: '1.2rem' }}>PREMIUM</Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell align="center" sx={{ fontWeight: 'bold', width: '180px', fontSize: '1rem' }}>가격</TableCell>
            <TableCell align="center">무료</TableCell>
            <TableCell align="center">
              무료<Typography component="span" sx={{ fontWeight: 'bold', color: 'blue', fontSize: '1.1rem' }}>*</Typography>
            </TableCell>
            <TableCell align="center">별도 문의</TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="center" sx={{ fontWeight: 'bold', fontSize: '1rem' }}>클라우드 용량</TableCell>
            <TableCell align="center">10MB</TableCell>
            <TableCell align="center">30MB</TableCell>
            <TableCell align="center">300MB</TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="center" sx={{ fontWeight: 'bold', fontSize: '1rem' }}>도면 모듈 이용</TableCell>
            <TableCell align="center">X</TableCell>
            <TableCell align="center">O</TableCell>
            <TableCell align="center">O</TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="center" sx={{ fontWeight: 'bold', fontSize: '1rem' }}>시트 모듈 이용</TableCell>
            <TableCell align="center">X</TableCell>
            <TableCell align="center">O</TableCell>
            <TableCell align="center">O</TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="center" sx={{ fontWeight: 'bold', fontSize: '1rem' }}>문서(타공종) 이용</TableCell>
            <TableCell align="center">O</TableCell>
            <TableCell align="center">O</TableCell>
            <TableCell align="center">O</TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="center" sx={{ fontWeight: 'bold', fontSize: '1rem' }}>문서(독소조항) 이용</TableCell>
            <TableCell align="center">Parsing : 3회<br />Analysis : 1회</TableCell>
            <TableCell align="center">Parsing : 10회<br />Analysis : 3회</TableCell>
            <TableCell align="center">Parsing : 50회<br />Analysis : 15회</TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <Typography variant="subtitle2" sx={{ textAlign: 'flex-end', margin: 2, fontWeight: 'bold', color: blue[500] }}>
        * dxeng@wise.co.kr으로 이름, 이메일, 전화번호 보내주시면 STANDARD 등급으로 무료 변경해드립니다.
      </Typography>
    </TableContainer>
  );
};
