const privatePolicy = (
`제 1조 (목적)
(주)위세아이텍(이하 '회사'라고 함)는 회사가 제공하고자 하는 서비스(이하 '회사 서비스')를 이용하는 개인(이하 '이용자' 또는 '개인')의 정보(이하 '개인정보')를 보호하기 위해 개인정보보호법, 정보통신망 이용촉진 및 정보보호 등에 관한 법률(이하 '정보통신망법') 등 관련 법령을 준수하고,서비스 이용자의 개인정보 보호 관련한 고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과 같이 개인정보처리방침 (이하 '본 방침')을 수립합니다.

회사는 개인정보보호법 등 관련 법령상의 개인정보보호 규정을 준수하며 귀하의 개인정보보호에 최선을 다하고 있습니다. 회사는 개인정보보호법에 근거하여 다음과 같은 내용으로 개인정보를 수집 및 처리하고자 합니다.

다음의 내용을 자세히 읽어보시고 모든 내용을 이해하신 후에 동의 여부를 결정해주시기 바랍니다.

제2조(개인정보 처리의 원칙)
개인정보 관련 법령 및 본 방침에 따라 회사는 이용자의 개인정보를 수집할 수 있으며 수집된 개인정보는 개인의 동의가 있는 경우에 한해 제3자에게 제공될 수 있습니다. 단,법령의 규정 등에 의해 적법하게 강제되는 경우 회사는 수집한 이용자의 개인정보를 사전에 개인의 동의 없이 제3자에게 제공할 수도 있습니다.

제3조(본 방침의 공개)
  1. 회사는 이용자가 언제든지 쉽게 본 방침을 확인할 수 있도록 회사 홈페이지 첫 화면 또는 첫 화면과의 연결화면을 통해 본 방침을 공개하고 있습니다.
  2. 회사는 제 1항에 따라 본 방침을 공개하는 경우 글자 크기, 색상 등을 활용하여 이용자가 본 방침을 쉽게 확인할 수 있도록 합니다.

제4조(본 방침의 변경)
  1. 본 방침은 개인정보 관련 법령,지침,고시 또는 정부나 회사 서비스의 정책이나 내용의 변경에 따라 개정될 수 있습니다.
  2. 회사는 제 1항에 따라 본 방침을 개정하는 경우 다음 각 호 하나 이상의 방법으로 공지합니다.
    가. 회사가 운영하는 인터넷 홈페이지의 첫 화면의 공지사항란 또는 별도의 창을 통하여 공지하는 방법
    나. 서면•모사전송•전자우편 또는 이와 비슷한 방법으로 이용자에게 공지하는 방법
  3. 회사는 제2항의 공지는 본 방침 개정의 시행일로부터 최소 7일 이전에 공지합니다. 다만, 이용자 권리의 중요한 변경이 있을 경우에는 최소 30일 전에 공지합니다.

제5조(회원 가입을 위한 정보)
회사는 이용자의 회사 서비스에 대한 회원가입을 위하여 다음과 같은 정보를 수집합니다.
  1. 필수수집 정보: 이메일 주소, 비밀번호, 이름, 생년월일, 전화번호, 성별, 소속 및 직책

제6조(본인 인증을 위한 정보)
회사는 이용자의 본인인증을 위하여 다음과 같은 정보를 수집합니다.
  [필수 수집 정보］ : 이메일 주소

제7조(회사 서비스 제공을 위한 정보)
회사는 이용자에게 회사의 서 비스를 제공하기 위하여 다음과 같은 정보를 수집합니다.
  1. 필수 수집 정보:이메일주소, 이름, 소속 및직책

제8조(개인정보 수집 방법)
회사는 다음과 같은 방법으로 이용자의 개인정보를 수집합니다.
  1. 이용자가 회사의 홈페이지에 자신의 개인정보를 입력하는 방식
  2. 어플리케이션 등 회사가 제공하는 홈페이지 외의 서비스를 통해 이용자가 자신의 개인정보를 입력하는 방식

제9조(개인정보의 이용)
회사는 개인정보를 다음 각 호의 경우에 이용합니다.
  1. 공지사항의 전달 등 회사운영에 필요한 경우
  2. 이용문의에 대한 회신, 불만의 처리 등 이용자에 대한 서비스 개선을위한 경우
  3. 회사의 서비스를 제공하기 위한 경우
  4. 법령 및 회사약관을위반하는회원에 대한이용제한조치, 부정 이용행위를 포함하여 서비스의 원활한 운영에 지장을 주는 행위에 대한 방지 및 제재를 위한 경우

제10조(개인정보의 보유 및 이용기간)
  1. 회사는 수집한 개인정보에 대해 수집•이용 동의 일로부터 회원탈퇴시까지 보관 및 이용합니다.
  2. 전항에도 불구하고 회사는 내부 방침에 의해 서비스 부정이용기록은 부정 가입 및 이용 방지를 위하여 회원 탈퇴 후에도 보관하며, 처리목적의 달성 등 개인정보가 불필요하게 되었을 때에는 지체없이 해당 개인정보를 파기합니다.

제11조(법령에 따른 개인정보의 보유 및 이용기간)
회사는 관계법령에 따라 다음과 같이 개인정보를 보유 및 이용합니다.
  1. 전자상거래 등에서의 소비자보호에 관한 법률에 따른 보유정보 및 보유기간
    가. 계약 또는청약철회등에관한 기록 : 5년
    나. 대금결제 및 재화 등의 공급에 관한 기록 : 5년
    다. 소비자의불만 또는 분쟁처리에관한 기록 : 3년
    라. 표시•광고에관한 기록 : 6개월
  2. 통신비밀보호법에 따른 보유정보 및 보유기간
    가. 웹사이트 로그 기록 자료 : 3개월
  3. 전자금융거래법에 따른 보유정보 및 보유기간
    가. 전자금융거래에관한 기록 : 5년

제12조(개인정보의 파기원칙)
회사는 원칙적으로 이용자의 개인정보 처리 목적의 달성,보유•이용기간의 경과 등 개인정보가 필요하지 않을 경우에는 해당 정보를 지체 없이 파기합니다.

제 13조(개인정보파기절차)
  1. 이용자가 회원가입 등을 위해 입력한 정보는 개인정보 처리 목적이 달성된 후 별도의 DB로 옮겨져(종이의 경우 별도의 서류함) 내부 방침 및 기타 관련 법령에 의한 정보보호 사유에 따라(보유 및 이용기간 참조) 일정 기간 저장된 후 파기 되어집니다.
  2. 회사는 파기 사유가 발생한 개인정보를 개인정보보호 책임자의 승인절차를 거쳐 파기합니다.

제 14조(개인정보파기방법)
회사는 전자적 파일형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제하며, 종이로 출력된 개인정보는 분쇄기로 분쇄하거나 소각 등을 통하여 파기합니다.

제15조(광고성 정보의 전송 조치)
  1. 회사는 전자적 전송매체를 이용하여 영리목적의 광고성 정보를 전송하는 경우 이용자의 명시적인 사전동의를 받습니다. 다만, 다음 각호 어느 하나에 해당하는 경우에는 사전 동의를 받지 않습니다.
    가. 회사가 서비스 등의 거래관계를 통하여 수신자로부터 직접 연락처를 수집한 경우, 거래가 종료된 날로부터 6개월 이내에 회사가 처리하고 수신자와 거래한 것과 동종의 서비스 등에 대한 영리목적의 광고성 정보를 전송하려는 경우
    나. 「방문판매 등에 관한 법률」에 따른 전화권유판매자가 육성으로 수신자에게 개인정보의 수집출처를 고지하고 전화권유를 하는 경우
  2. 회사는 전항에도 불구하고 수신자가 수신거부의사를 표시하거나 사전 동의를 철회한 경우에는 영리목적의 광고성 정보를 전송하지 않으며 수신거부 및 수신동의 철회에 대한 처리 결과를 알립니다.
  3. 회사는 오후 9시부터 그다음 날 오전 8시까지의 시간에 전자적 전송매체를 이용하여 영리목적의 광고성 정보를 전송하는 경우에는 제 1항에도 불구하고 그 수신자로부터 별도의 사전 동의를 받습니다.
  4. 회사는 전자적 전송매체를 이용하여 영리목적의 광고성 정보를 전송하는 경우 다음의 사항 등을 광고성 정보에 구체적으로 밝힙니다.
    가. 회사명및연락처
    나. 수신 거부 또는 수신 동의의 철회 의사표시에 관한 사항의 표시
  5. 회사는 전자적 전송매체를 이용하여 영 리목적의 광고성 정보를 전송하는 경우 다음 각 호의 어느 하나에 해당하는 조치를 하지 않습니다.
    가. 광고성 정보수신자의 수신거부또는 수신동의의 철회를 회피•방해하는 조치
    나. 숫자•부호 또는 문자를 조합하여 전화번호•전자우편주소 등 수신자의 연락처를 자동으로 만들어 내는 조치
    다. 영리목적의 광고성 정보를 전송할 목적으로 전화번호 또는 전자우편주소를 자동으로 등록하는 조치
    라. 광고성 정보 전송자의 신원이나 광고 전송 출처를 감추기 위한 각종 조치
    마. 영 리목적의 광고성 정보를 전송할 목적으로 수신자를 기망하여 회신을 유도하는 각종 조치

제16조(아동의 개인정보보호)
  1. 회사는 만 14세 미만 아동의 개인정보 보호를 위하여 만 19세 이상의 이용자에 한하여 회원가입을 허용합니다.

제17조(개인정보 조회 및 수집동의 철회)
  1. 이용자 및 법정 대리인은 언제든지 등록되어 있는 자신의 개인정보를 조회하거나 수정할 수 있으며 개인정보수집 동의 철회를 요청할수 있습니다.
  2. 이용자 및 법정 대리인은 자신의 가입정보 수집 등에 대한 동의를 철회하기 위해서는 개인정보보호책임자 또는 담당자에게 서면, 전화 또는 전자우편주소로 연락하시면 회사는 지체 없이 조치하겠습니다.

제18조(개인정보 정보변경 등)
  1. 이용자는 회사에게 전조의 방법을 통해 개인정보의 오류에 대한 정정을 요청할 수 있습니다.
  2. 회사는 전항의 경우에 개인정보의 정정을 완료하기 전까지 개인정보를 이용 또는 제공하지 않으며 잘못된 개인정보를 제3자에게 이미 제공한 경우에는 정정 처리결과를 제3자에게 지체 없이 통지하여 정정이 이루어지도록 하겠습니다.

제19조(이용자의 의무)
  1. 이용자는 자신의 개인정보를 최신의 상태로 유지해야 하며, 이용자의 부정확한 정보 입력으로 발생하는 문제의 책임은 이용자 자신에게 있습니다.
  2. 타인의 개인정보를 도용한 회원가입의 경우 이용자 자격을 상실하거나 관련 개인정보보호 법령에 의해 처벌받을 수 있습니다.
  3. 이용자는 전자우편주소,비밀번호 등에 대한 보안을 유지할책임이 있으며 제3자에게 이를 양도하거나 대여할 수 없습니다.

제20조(회사의 개인정보 관리)
회사는 이용자의 개인정보를 처리함에 있어 개인정보가 분실, 도난, 유출, 변조, 훼손 등이 되지 아니하도록 안전성을 확보하기 위하여 필요한 기술적•관리적 보호대책을 강구하고 있습니다.

제21조(삭제된 정보의 처리)
회사는 이용자 혹은 법정 대리인의 요청에 의해 해지 또는 삭제된 개인정보는 회사가 수집하는 "개인정보의 보유 및 이용기간”에 명시된 바에 따라 처리하고 그 외의 용도로 열람 또는 이용할 수 없도록 처리하고 있습니다.

제22조(비밀번호의 암호화)
이용자의 비밀번호는 일방향 암호화하여 저장 및 관리되고 있으며, 개인정보의 확인, 변경은 비밀번호를 알고 있는 본인에 의해서만 가능합니다.

제23조(해킹 등에 대비한 대책)
  1. 회사는 해킹, 컴퓨터 바이러스 등 정보통신망 침입에 의해 이용자의 개인정보가 유출되거나 훼손되는 것을 막기 위해 최선을 다하고 있습니다.
  2. 회사는 최신 백신프로그램을 이용하여 이용자들의 개인정보나 자료가 유출 또는 손상되지 않도록 방지하고 있습니다.
  3. 회사는 만일의 사태에 대비하여 침입차단 시스템을 이용하여 보안에 최선을 다하고 있습니다.
  4. 회사는 민감한 개인정보(를 수집 및 보유하고 있는 경우)를 암호화 통신 등을 통하여 네트워크상에서 개인정보를 안전하게 전송할 수 있도록 하고 있습니다.

제24조(개인정보 처리 최소화 및 교육)
회사는 개인정보 관련 처리 책임자를 최소한으로 제한하며, 개인정보 처리자에 대한 교육 등 관리적 조치를 통해 법령 및 내부방침 등의 준수를 강조하고 있습니다.

제25조(개인정보 자동 수집 장치의 설치•운영 및 거부에 관한 사항)
  1. 회사는 이용자에게 개별적인 맞춤서비스를 제공하기 위해 이용 정보를 저장하고 수시로 불러오는 개인정보 자동 수집장치(이하 '쿠키')를 사용합니다. 쿠키는 웹사이트를 운영하는데 이용되는 서버(http)가 이용자의 웹브라우저(PC 및 모바일을 포함)에게 보내는 소량의 정보이며 이용자의 저장공간에 저장되기도합니다.
  2. 이용자는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서 이용자는 웹브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 아니면 모든 쿠키의 저장을 거부할 수도 있습니다.
  3. 다만, 쿠키의 저장을 거부할 경우에는 로그인이 필요한 회사의 일부 서비스는 이용에 어려움이 있을 수 있습니다.

제26조(쿠키 설치 허용 지정 방법)
웹브라우저 옵션 설정을 통해 쿠키 허용, 쿠키 차단 등의 설정을 할 수 있습니다.
  1. Edge : 웹브라우저 우측 상단의 설정 메뉴〉쿠키 및 사이트 권한〉쿠키 및 사이트 데이터 관리 및 삭제
  2. Chrome : 웹브라우저 우측 상단의 설정 메뉴 > 개인정보 및 보안〉쿠키 및 기타 사이트 데이터
  3. Whale : 웹브라우저 우측 상단의 설정 메뉴 > 개인정보 보호〉쿠키 및 기타 사이트 데이터

제27조(회사의 개인정보 보호 책임자 지정)
  1. 회사는 이용자의 개인정보를 보호하고 개인정보와 관련한 불만을 처리하기 위하여 아래와 같이 관련 부서 및 개인정보 보호 책임자를 지정하고 있습니다.
    가. 개인정보보호책임자
      1) 성명: 김현수
      2) 직책: 팀장
      3) 전화번호: 02-6246-1400
      4) 이메일: dxeng@wise.co.kr

제28조(권익침해에 대한 구제방법)
  1. 정보주체는 개인정보침해로 인한 구제를 받기 위하여 개인정보분쟁조정위원회, 한국인터넷진흥원 개인정보침해신고센터 등에 분쟁해결이나 상담 등을 신청할 수 있습니다. 이 밖에 기타 개인정보침해의 신고, 상담에 대하여는 아래의 기관에 문의하시기 바랍니다.
    가. 개인정보분쟁조정위원회 : (국번없이 ) 1833-6972 (www.kopico.go.kr)
    나. 개인정보침해신고센터 : (국번없이) 118 (privacy.kisa.or.kr)
    다. 대검찰청 : (국번없이) 1301 (www.spo.go.kr)
    라. 경찰청 : (국번없이) 182 (ecrm.cyber.go.kr)
  2. 회사는 정보주체의 개인정보자기결정권을 보장하고, 개인정보침해로 인한 상담 및 피해 구제를 위해 노력하고 있으며, 신고나 상담이 필요한 경우 제1항의 담당부서로 연락해주시기 바랍니다.
  3. 개인정보 보호법 제35조(개인정보의 열람), 제36조(개인정보의 정정•삭제), 제37조(개인정보의 처리정지 등)의 규정에 의한 요구에 대 하여 공공기관의 장이 행한 처분 또는 부작위로 인하여 권리 또는 이익의 침해를 받은 자는 행정 심판법이 정하는 바에 따라 행정 심판을 청구할 수 있습니다.
    가. 중앙행정심판위원회 : (국번없이) 110 (www.simpan.go.kr)

제29조(동의 거부 관리)
귀하는 본 안내에 따른 개인정보 수집•이용에 대하여 동의를 거부할 권리가 있습니다. 다만, 귀하가 개인정보 동의를 거부하시는 경우에 서비스 이용 제한 등의 불이익이 발생할 수 있음을 알려드립니다.

본인은 위의 동의서 내용을 충분히 숙지하였으며, 위와 같이 개인정보를 수집•이용하는데 동의합니다.

부칙
제1조 본 방침은 2023.01.18.부터 시행됩니다.
  `
);

const servicePolicy = (
`제 1조 (목적)
  1. 본 약관은 (주)위세아이텍가 운영하는 웹 플랫폼 'DXENG(https://dxeng.net)'에서 제공하는 서비스(이하 '서비스'라 합니다)를 이용함에 있어 당사자의 권리 의무 및 책임사항을 규정하는 것을 목적으로 합니다.
  2. PC통신, 무선 등을 이용하는 전자상거래에 대해서도 그 성질에 반하지 않는 한 본 약관을 준용합니다.

제2조 (정의)
  1. '회사'라 함은, '(주)위세아이텍가 디지털 정보 처리 및 가공 서비스를 이용자에게 제공하기 위한 웹 플랫폼을 운영하는 사업자를 말하며, 아울러 'DXENG(https://dxeng.net)'을 통해 제공되는 서비스의 의미로도 사용합니다.
  2. '이용자'라 함은, '사이트'에 접속하여 본 약관에 따라 |회사'가 제공하는 서비스를 받는 회원을 말합니다.
  3. '회원'이라 함은, '회사'에 개인정보를 제공하고 회원으로 등록한 자로서, '회사'의 서비스를 계속하여 이용할 수 있는 자를 말합니다.

제3조(약관 외 준칙)
본 약관에서 정하지 아니한 사항은 법령 또는 회사가 정한 서비스의 개별 약관, 운영정책 및 규칙(이하'세부지침'이라 합니다)의 규정에 따릅니다. 또한 본 약관과 세부지침이 충돌할 경우에는 세부지침이 우선합니다.

제4조(약관의 명시 및 개정)
  1. '회사'는 이 약관의 내용과 상호, 영업소 소재지, 전화번호, 모사전송번호(FAXh 전자우편주소 등을 이용자가 쉽게 알 수 있도록 '회사' 홈페이지의 초기 서비스화면에 게시합니다. 다만 본 약관의 내용은 '이용자'가 연결화면을 통하여 확인할 수 있도록 할 수 있습니다.
  2. '회사'가 본 약관을 개정하고자 할 경우, 적용일자 및 개정사유를 명시하여 현행약관과 함께 웹 플랫폼의초기화면에 그 적용일자 7일전부터 적용일자 전날까지 공지합니다. 다만, '이용자'에게 불리한 내용으로 약관을 변경하는 경우 최소 30일 이상 유예기간을 두고 공지합니다.
  3. '회사'가 본 약관을 개정한 경우, 개정약관은 적용일자 이후 체결되는 계약에만 적용되며 적용일자 이전 체결된 계약에 대해서는 개정 전 약관이 적용됩니다. 다만, 이미 계약을 체결한 '이용자'가 개정약관의 내용을 적용받고자 하는 뜻을 '회사'에 전달하고 '회사'가 여기에 동의한 경우 개정약관을 적용합니다.
  4. 본 약관에서 정하지 아니한 사항 및 본 약관의 해석에 관하여는 관계법령 및 건전한 상관례에 따릅니다.

제5조(제공하는 서비스)
'회사'는 다음의 서비스를 제공합니다.
  1. 클라우드 데이터 관리, 모듈 다운로드, 디지털 정보에 대한 처리 및 가공
  2. 기타 '회사가정하는업무

제6조(서비스의 중단 등)
  1. '회사'가 제공하는 서비스는 연중무휴,1일 24시간 제공을 원칙으로 합니다. 다만 '회사' 시스템의 유지 • 보수를 위한 점검, 통신장비의 교체 등 특별한 사유가 있는 경우 서비스의 전부 또는 일부에 대하여 일시적인 제공 중단이 발생할 수 있습니다.
  2. '회사'는 전시, 사변, 천재지변 또는 이에 준하는 국가비상사태가 발생하거나 발생할 우려가 있는 경우, 전기통신사업법에 의한 기간통신사업자가 전기통신서비스를 중지하는 등 부득이한 사유가 발생한 경우 서비스의 전부 또는 일부를 제한하거나 중지할 수 있습니다.
  3. '회사'가 서비스를 정지하거나 이용을 제한하는 경우 그 사유 및 기간, 복구 예정 일시 등을 지체 없이 '이용자'에게 알립니다.

제7조 (회원가입)
  1. '회사”가 정한 양식에 따라 '이용자'가 회원정보를 기입한 후 본 약관에 동의한다는 의사표시를 함으로써 회원가입을 신청합니다.
  2. '회사'는 전항에 따라 회원가입을 신청한 '이용자' 중 다음 각호의 사유가 없는 한 '회원'으로 등록합니다.
    가. 가입신청자가본 약관에 따라회원자격을상실한적이 있는 경우. 다만, '회사'의 재가입 승낙을 얻은 경우에는 예외로 합니다.
    나. 회원정보에 허위, 기재누락, 오기 등 불완전한 부분이 있는 경우
    다. 기타 회원으로 등록하는 것이 '회사'의 운영에 현저한 지장을 초래하는 것으로 인정되는 경우
  3. 회원가입 시기는 '회사'의 가입승낙 안내가 '회원'에게 도달한 시점으로 합니다.

제8조(회원탈퇴 및 자격상실 등)
  1. '회원'은 '회사'에 언제든지 탈퇴를 요청할 수 있으며, '회사'는 지체없이 회원탈퇴 요청을 처리합니다. 다만 이미 체결된 거래계약을 이행할 필요가 있는 경우에는 본약관이 계속 적용됩니다.
  2. '웹 플랫폼'은 다음 각호의 사유가 발생한 경우 '회사'의 자격을 제한 또는 정지시킬 수 있습니다.
    가. 회원가입시허위정보를기재한경우
    나. 다른 이용자의 정상적 인 이용을 방해하는 경우
    다. 관계법령 또는 본 약관에서 금지하는 행위를 한 경우
    라. 공서 양속에어긋나는 행위를 한 경우
    마. 기타 '회원'으로 등록하는 것이 적절하지 않은 것으로 판단되는 경우

제9조(회원에 대한 통지)
  1. '회사'는 '회원' 회원가입 시 기재한 전자우편, 이동전화번호 등을 이용하여 '회원'에게 통지 할 수 있습니다.
  2. '회사'가 불특정 다수 '회원'에게 통지하고자 하는 경우 1주일 이상 '사이트'의 게시판에 게시함으로써 개별 통지에 갈음할수 있습니다. 다만 |회원'이 서비스를 이용함에 있어 중요한 사항에 대하여는 개별 통지합니다.

제 10조(개인정보보호)
  1. '회사'는 '이용자'의 정보수집시 다음의 필수사항등 서비스 이용에 필요한최소한의 정보만을 수집 합니다.
    가. 성명
    나. 전화번호(또는 이동전화번호)
    다. 비밀번호
    라. 전자우편(e-mail)주소
    마. 성별
    바. 생년월일
    사. 소속
    아. 직책
  2. '회사'가 개인정보보호법 상의 고유식별정보 및 민감정보를 수집하는 때에는 반드시 대상자의 동의를 받습니다.
  3. 본 약관에 기재된 사항 이외의 개인정보보호에 관한 사항은 '회사'의 '개인정보처리방침'에 따릅니다.

제11조('회사'의 의무)
  1. '회사'는 관계법령, 본 약관이 금지하거나 공서양속에 반하는 행위를 하지 않으며 약관이 정하는 바에 따라 지속적 . 안정적으로 서비스를 제공하는데 최선을 다하여야 합니다.
  2. '회사'는 '이용자'가 안전하게 인터넷 서비스를 이용할 수 있도록 개인정보 보호를 위한 보안 시스템을 갖추어야 합니다.
  3. '회사'가 상품에 대하여 '표시 • 광고의 공정화에 관한 법률' 제3조 소정의 부당한 표시 • 광고행위를 하여 '이용자'가 손해를 입은 때에는 이를 배상할 책임을 집니다.
  4. '회사'는 '이용자'의 수신동의 없이 영리목적으로 광고성 전자우편, 휴대전화 메시지, 전화, 우편 등을 발송하지 않습니다.

제12조(이용자 및 회원의 의무)
  1. '이용자'는 회원가입 신청 시 사실에 근거하여 신청서를 작성해야 합니다. 허위, 또는 타인의 정보를 등록한 경우 '회사'에 대하여 일체의 권리를 주장할 수 없으며, '회사'는 이로 인하여 발생한 손해에 대하여 책임을 부담하지 않습니다.
  2. '이용자'는 본 약관에서 규정하는 사항과 기타 '회사'가 정한 제반 규정 및 공지사항을 준수하여야 합니다. 또한 '이용자'는 '회사'의 업무를 방해하는 행위 및 '회사'의 명예를 훼손하는 행위를 하여서는 안됩니다.
  3. '이용자'는 연락처 등 회원정보가 변경된 경우 즉시 이를 수정해야 합니다. 변경된 정보를 수정하지 않거나 수정을 게을리하여 발생하는 책임은 '이용자'가 부담합니다.
  4. '이용자'는 다음의 행위를 하여서는 안됩니다.
    가. '회사'에 게시된 정보의 변경
    나. '회사'가 정한 정보 외의 다른 정보의 송신 또는 게시
    다. '회사' 및 제3자의 저작권 등 지식재산권에 대한 침해
    라. '회사' 및 제3자의 명예를 훼손하거나 업무를 방해하는 행위
    마. 외설 또는 폭력적인 메시지, 화상, 음성 기타 관계법령 및 공서양속에 반하는 정보를 '회사'의 '사이트'에 공개 또는 게시하는 행위
  5. '회원'은 부여된 아이디(ID)와 비밀번호를 직접 관리 해야합니다.
  6. '회원'이 자신의 아이디(ID) 및 비밀번호를 도난당하거나 제3자가 사용하고 있음을 인지한 경우에는 바로 '회사'에 통보하고 안내에 따라야 합니다.

제13조(분쟁의 해결)
  1. '회사'는 '이용자'가 제기하는 불만사항 및 의견을 지체없이 처리하기 위하여 노력합니다. 다만 신속한 처리가 곤란한 경우 '이용자'에게 그 사유와 처리일정을 즉시 통보해 드립니다.
  2. '회사'와 '이용자'간 전자상거래에 관한 분쟁이 발생한 경우, '이용자'는 한국소비자원, 전자문서 • 전자거래분쟁조정위원회 등 분쟁조정 기관에 조정을 신청할 수 있습니다.
  3. '회사'와 '이용자'간 발생한 분쟁에 관한 소송은 '회사' 소재지를 관할하는 법원을 제1심 관할법원으로 하며, 준거법은 대한민국의 법령을 적용합니다.

부 칙
제 1조(시행일)
본 약관은 2023.01.18.부터 적용합니다.
`
);

export {privatePolicy, servicePolicy };