import { combineReducers } from "redux";
import emailReducer from "./login/emailReducer";
import pwReducer from "./login/pwReducer";
import nameReducer from "./login/nameReducer";
import phoneReducer from "./login/phoneReducer";
import birthReducer from "./login/birthReducer";
import buttonStatusReducer from "./common/buttonStatusReducer";
import alertReducer from "./common/alertReducer";
import readOnlyStatusReducer from "./login/readOnlyStatusReducer";
import resultReducer from "./common/resultReducer";
import authCodeReducer from "./login/authKeyReducer";
import orgReducer from "./login/orgReducer";
import jobReducer from "./login/jobReducer";
import genderReducer from "./login/genderReducer";
import userInfoReducer from "./user/userInfoReducer";
import cloudReducer from "./cloud/cloudReducer";
import administratorReducer from "./admin/adminReducer";
import visualReducer from "./module/drawVisualReducer";
import riskReducer from "./module/docRiskReducer";
import docVisualReducer from "./module/docVisualReducer";
import crossReducer from "./module/docCrossReducer";

export default combineReducers({
  ...emailReducer,
  ...pwReducer,
  ...nameReducer,
  ...phoneReducer,
  ...birthReducer,
  ...buttonStatusReducer,
  ...alertReducer,
  ...readOnlyStatusReducer,
  ...resultReducer,
  ...authCodeReducer,
  ...orgReducer,
  ...jobReducer,
  ...genderReducer,
  ...userInfoReducer,
  ...cloudReducer,
  ...administratorReducer,
  ...visualReducer,
  ...riskReducer,
  ...docVisualReducer,
  ...crossReducer,
});
