export const SET_DOC_TEST_FILE = "SET_DOC_TEST_FILE";
export const SET_DOC_TEST_CLOUD_ALERT = "SET_DOC_TEST_CLOUD_ALERT";

export function setDocTestFile(docTestFile = "") {
  return {
    type: SET_DOC_TEST_FILE,
    docTestFile,
  };
}

export function setDocTestCloudAlert(docTestCloudAlert) {
  return {
    type: SET_DOC_TEST_CLOUD_ALERT,
    docTestCloudAlert,
  };
}
