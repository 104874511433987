import {
  Box,
  Button,
  // Grid,
  // LinearProgress,
  // Paper,
  // Table,
  // TableBody,
  // TableCell,
  // TableContainer,
  // TableHead,
  // TableRow,
  Typography,
} from "@mui/material";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export default function DocVisualCrossVisualMeta() {
  const navigate = useNavigate();

  const crossFileName = useSelector((state) => state.crossFileName);

  const handleButtonClick = () => {
    navigate("/module/docvisual/cross");
  };

  return (
    <Box
      sx={{
        border: "1px solid #ccc",
        borderRadius: 2,
        p: 3,
        mb: 2,
        backgroundColor: "#f9f9f9",
      }}
    >
      <Box
        sx={{
          mb: 2,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "end",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          <Typography variant="h5">
            {" "}
            <strong>프로젝트명: {crossFileName}</strong>{" "}
          </Typography>
        </Box>
        <Box sx={{ display: "flex" }}>
          <Button
            sx={{
              display: "flex",
              alignItems: "center",
              px: 1,
              whiteSpace: "nowrap",
            }}
            size="small"
            onClick={handleButtonClick}
          >
            {" "}
            홈으로{" "}
          </Button>
        </Box>
      </Box>

      <Box>
        <Box sx={{ display: "flex", flexWrap: "wrap" }}>
          {/* <Box sx={{ width: "15%", mr: 2 }}>
            <strong>작성자:</strong> -
          </Box> */}
          {/* <Box sx={{ width: "15%", mr: 2 }}>
            <strong>발주사:</strong> ABC 주식회사
          </Box> */}
          {/* <Box sx={{ width: "15%", mr: 2 }}>
            <strong>작성일:</strong> -
          </Box> */}
          {/* <Box sx={{ width: "15%", mr: 2 }}>
            <strong>장소:</strong> 서울
          </Box>
          <Box sx={{ width: "15%", mr: 2 }}>
            <strong>종류:</strong> 원자력
          </Box> */}
        </Box>
      </Box>

      {/* <Grid container spacing={6}>
        <Grid item xs={12} sm={6} md={5}>
          <TableContainer sx={{ width: '80%', backgroundColor: 'transparent', border: '1px solid #ccc' }}>
            <Table size="small" sx={{ borderCollapse: 'collapse' }}>
              <TableHead>
                <TableRow>
                  <TableCell align="center" sx={{ backgroundColor: 'transparent' }}></TableCell>
                  <TableCell align="center"><strong>해당 공종 정보</strong></TableCell>
                  <TableCell align="center"><strong>비교 공종 정보</strong></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell align="center" sx={{ backgroundColor: 'transparent' }}><strong>공종</strong></TableCell>
                  <TableCell align="center">Pipe</TableCell>
                  <TableCell align="center">machine</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center" sx={{ backgroundColor: 'transparent' }}><strong>관리자</strong></TableCell>
                  <TableCell align="center">김철수</TableCell>
                  <TableCell align="center">이영희</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        <Grid item xs={12} sm={12} md={6}>
          <Paper elevation={6} sx={{ mt:2, p: 1, height: '70%' }}>
            <Typography variant="body1" sx={{ fontWeight: 'bold', mb: 1 }}>
              pipe과 machine에 대한 검증 진행률 (1/5문장)
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <LinearProgress 
                variant="determinate" 
                value={20} 
                sx={{ width: '100%', height: 20, borderRadius: 1, bgcolor: '#e0e0e0', '& .MuiLinearProgress-bar': { bgcolor: '#009688' } }} 
              />
              <Box sx={{ ml:1, minWidth: 35 }}>
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                  {`${20}%`}
                </Typography>
              </Box>
            </Box>
          </Paper>
        </Grid>
      </Grid> */}
    </Box>
  );
}
