import axios from "axios";

class ProjectInfo {
  /**
   * @property {string} created_date - 생성 날짜
   * @property {string} modified_date - 마지막 수정 날짜
   * @property {string} description - 설명
   */
  constructor(created_date, modified_date, description) {
    this.created_date = created_date;
    this.modified_date = modified_date;
    this.description = description;
  }
}

class SheetPage {
  /**
   * @property {number} page_num - 페이지 번호
   * @property {Object<string, SheetTable|SheetCell|SheetText|SemanticText>} all_objs - 모든 객체들. key: id, value: 객체
   * @property {Array<SheetTable>} inner_tables - 내부 표 리스트
   * @property {Array<SheetText>} inner_texts - 표 밖에 위치한 텍스트 리스트
   * @property {boolean} is_target - 인식 대상 여부
   * @property {number} width - 이미지 너비
   * @property {number} height - 이미지 높이
   * @property {string} original_img_path - 원본 이미지 경로
   * @property {string} rebuilt_img_path - 재구성 이미지 경로
   * @property {Array<Array<number>>} obj_map - 각 픽셀에 객체 id를 저장한 2차원 배열, 마우스 클릭 좌표로 객체 찾을 때 사용
   * @property {Map<number, SheetLine>} lines - 선 객체들. key: id, value: 객체
   * @property {Array<RelationTitle | Relation11 | Relation1D | Relation2D>} relation_tree - 관계 트리
   * @property {Map<string, RelationTitle | Relation11 | Relation1D | Relation2D>} relation_list - 관계 트리
   */

  constructor(page_num, is_target, width, height, project_dir) {
    this.page_num = page_num;
    this.all_objs = {};
    this.inner_tables = [];
    this.inner_texts = [];
    this.is_target = is_target;
    this.obj_map = null;
    this.width = width;
    this.height = height;
    this.original_img_path = `${page_num}_original_img.png`;
    this.rebuilt_img_path = `${page_num}_rebuilt_img.png`;
    this.lines = new Map();
    this.relation_tree = [];
    this.relation_list = new Map();
  }
}

class SheetLine {
  /**
   * @property {number} id
   * @property {[number, number]} p1 - 시작점 좌표
   * @property {[number, number]} p2 - 끝점 좌표
   * @property {Map<string, string>} linked_cells - 선 양쪽에 연결된 셀 <방향, 셀 id>
   */

  constructor(id, p1, p2, linked_cells) {
    this.id = id;
    this.p1 = p1;
    this.p2 = p2;
    this.linked_cells = linked_cells;
  }

  static from_json(line_node) {
    const id = line_node["id"];
    const p1 = line_node["p1"];
    const p2 = line_node["p2"];
    const linked_cells = new Map(
      Object.entries(line_node["linked cells"]).map(([direction, cell_id]) => [
        direction, // 문자열 그대로 사용
        cell_id,
      ])
    );
    return new SheetLine(id, p1, p2, linked_cells);
  }
}

class SheetTable {
  /**
   * @property {string} id - 고유번호
   * @property {Array<Array<number>>} conner_points - 꼭짓점 좌표 [[x1, y1], [x2, y2]...]
   * @property {SheetCell} outer_cell - 표 안에 표인 경우, 이 표를 포함하고 있는 외부 셀
   * @property {Array<SheetCell>} inner_cells - 표 내부 셀 리스트
   */

  constructor(id, conner_points) {
    this.id = id;
    this.conner_points = conner_points;
    this.outer_cell = null;
    this.inner_cells = [];
  }
}

class SheetCell {
  /**
   * @property {string} id - 고유번호
   * @property {Array<Array<number>>} conner_points - 꼭짓점 좌표 [[x1, y1], [x2, y2]...]
   * @property {SheetTable} parent - 부모 표
   * @property {Object<string, Array<SheetCell>>} morp_link_cell - 연결된 셀들. key: 방향, value: 셀 리스트
   * @property {Array<SheetText>} inner_texts - 내부 텍스트 리스트
   * @property {Array<SheetTable>} inner_tables - 내부 표 리스트. 셀 내부에 표가 없으면 빈 리스트
   * @property {Array<SemanticText>} inner_semantic_texts - 내부 의미 텍스트 리스트
   * @property {Array<string>} properties - 속성 리스트. 비어 있을 수 있음
   * @property {Array<string>} values - 값 리스트. 비어 있을 수 있음
   * @property {Array<string>} separated_properties - 분리된 속성 리스트. 비어 있을 수 있음
   * @property {Array<string>} units - 단위 리스트. 비어 있을 수 있음
   *  @property {Array<SheetLine>} lines - 셀을 연결하는 선 리스트
   */
  constructor(id, conner_points, lines) {
    this.id = id;
    this.conner_points = conner_points;
    this.parent = null;
    this.morp_link_cell = {};
    this.inner_texts = [];
    this.inner_tables = [];
    this.inner_semantic_texts = [];
    this.properties = [];
    this.values = [];
    this.separated_properties = [];
    this.units = [];
    this.lines = lines;
  }

  get_box() {
    if (this.conner_points.length === 4) {
      const [p1, , p3] = this.conner_points;
      return [p1[0], p1[1], p3[0], p3[1]];
    } else {
      const min_x = Math.min(...this.conner_points.map((point) => point[0]));
      const max_x = Math.max(...this.conner_points.map((point) => point[0]));
      const min_y = Math.min(...this.conner_points.map((point) => point[1]));
      const max_y = Math.max(...this.conner_points.map((point) => point[1]));
      return [min_x, min_y, max_x, max_y];
    }
  }
}

class SheetText {
  /**
   * @property {string} id - 고유번호
   * @property {Array<Array<number>>} conner_points - 꼭짓점 좌표 [[x1, y1], [x2, y2]...]
   * @property {string} text - 텍스트
   * @property {SheetCell} parent - 부모 셀
   */

  constructor(id, conner_points, text) {
    this.id = id;
    this.conner_points = conner_points;
    this.text = text;
    this.parent = null;
  }
}

export class SemanticText {
  /**
   * @property {string} id - 고유번호
   * @property {Array<Array<number>>} conner_points - 꼭짓점 좌표 [[x1, y1], [x2, y2]...]
   * @property {string} text - 텍스트
   * @property {string} type - 의미 분류 key|value|separator|unit|annotation
   * @property {Array<string>} properties - 속성. type이 key인 경우 요소가 1개, separator인 경우 요소가 2개 이상, unit인 경우 없음
   * @property {string} unit - 단위. type이 unit인 경우만 존재
   * @property {SheetCell} parent - 부모 셀
   */
  constructor(id, conner_points, text, type, properties, unit) {
    this.id = id;
    this.conner_points = conner_points;
    this.text = text;
    this.type = type;
    this.properties = properties;
    this.unit = unit;
    this.parent = null;
  }

  get_box() {
    if (this.conner_points.length === 4) {
      const [p1, , p3] = this.conner_points;
      return [p1[0], p1[1], p3[0], p3[1]];
    } else {
      const min_x = Math.min(...this.conner_points.map((point) => point[0]));
      const max_x = Math.max(...this.conner_points.map((point) => point[0]));
      const min_y = Math.min(...this.conner_points.map((point) => point[1]));
      const max_y = Math.max(...this.conner_points.map((point) => point[1]));
      return [min_x, min_y, max_x, max_y];
    }
  }
}

class PropertyDict {
  /**
   * 속성 사전
   *
   * @param {string} representative - 대표 속성
   * @param {string} description - 설명
   * @param {Array<string>} synonyms - 대표 속성의 동의어
   */
  constructor(representative, description, synonyms) {
    this.representative = representative;
    this.description = description;
    this.synonyms = synonyms;
  }
}

class UnitDict {
  /**
   * 단위 사전
   *
   * @param {string} representative - 대표 단위 ex) m²
   * @param {string} description - 설명
   * @param {Array<string>} synonyms - 단위의 다른 표현 ex) m2
   */
  constructor(representative, description, synonyms) {
    this.representative = representative;
    this.description = description;
    this.synonyms = synonyms;
  }
}

class TextPattern {
  /**
   * 주석 및 구분자 패턴
   *
   * @param {RegExp} regex - 정규표현식
   * @param {Array<string>} examples - 정규표현식으로 찾을 수 있는 텍스트 예시
   */
  constructor(regex, examples) {
    this.regex = regex;
    this.examples = examples;
  }
}

class HeaderType {
  static row = "수직";
  static column = "수평";
}

class TitleType {
  static inner = "내부";
  static outer = "외부";
}

class RelationDictTitle {
  constructor(
    id,
    parent = null,
    keys = new Set(),
    type = TitleType.inner,
    sub_relations = []
  ) {
    this.id = id;
    this.parent = parent;
    this.keys = keys || new Set();
    this.type = type;
    this.sub_relations = sub_relations || [];
  }
}

class RelationDict11 {
  constructor(id, parent = null, keys = new Set()) {
    this.id = id;
    this.parent = parent;
    this.keys = keys || new Set();
  }
}

class RelationDict1D {
  constructor(
    id,
    parent = null,
    keys = new Set(),
    header_type = HeaderType.column
  ) {
    this.id = id;
    this.parent = parent;
    this.keys = keys || new Set();
    this.header_type = header_type;
  }
}

class RelationDict2D {
  constructor(
    id,
    parent = null,
    column_keys = new Set(),
    row_keys = new Set()
  ) {
    this.id = id;
    this.parent = parent;
    this.column_keys = column_keys || new Set();
    this.row_keys = row_keys || new Set();
  }
}

export class RelationTitle {
  // id: number
  // keys: Set<PropertyDict>
  // type: TitleType
  // obj: SheetCell | SheetText
  // parent: RelationTitle | null
  // children: Array<Relation11 | Relation1D | Relation2D | RelationTitle>

  constructor(id, type, keys, obj, children = []) {
    this.id = id;
    this.type = type;
    this.keys = keys; // Set 자료형
    this.obj = obj;
    this.children = children;
    this.parent = null; // 기본값은 null
  }

  /**
   * JSON 객체로부터 RelationTitle 객체를 생성합니다.
   *
   * @param {Object} json_dict - JSON 객체
   * @param {Object<string, PropertyDict>} text_to_property - 텍스트와 속성 객체의 매핑
   * @param {Object<string, UnitDict>} text_to_unit - 텍스트와 단위 객체의 매핑
   * @param {Object<string, SheetTable|SheetCell|SheetText|SemanticText>} all_obj - 모든 객체들
   * @param {Map<string, RelationTitle|Relation2D|Relation1D|Relation11>}relation_list
   */
  static from_dict(
    json_dict,
    text_to_property,
    text_to_unit,
    all_obj,
    relation_list
  ) {
    const id = json_dict["id"];
    const type = TitleType[json_dict["type"]];
    const keys = new Set(
      json_dict["keys"].map((key) => text_to_property[key.toLowerCase()])
    );
    const obj = all_obj[json_dict["obj"]];
    const children = [];

    for (const sub_node of json_dict["children"]) {
      let child;
      if (sub_node["class"] === "title") {
        child = RelationTitle.from_dict(
          sub_node,
          text_to_property,
          text_to_unit,
          all_obj,
          relation_list
        );
      } else if (sub_node["class"] === "1:1") {
        child = Relation11.from_dict(
          sub_node,
          text_to_property,
          text_to_unit,
          all_obj
        );
      } else if (sub_node["class"] === "1D") {
        child = Relation1D.from_dict(
          sub_node,
          text_to_property,
          text_to_unit,
          all_obj
        );
      } else {
        // sub_node["class"] === "2D"
        child = Relation2D.from_dict(
          sub_node,
          text_to_property,
          text_to_unit,
          all_obj
        );
      }
      relation_list.set(child.id, child);
      children.push(child);
    }

    return new RelationTitle(id, type, keys, obj, children);
  }
}

class HeaderItem {
  /**
   * @property {Array<PropertyDict>} keys - 헤더의 행/열 요소에 포함된 속성들
   * @property {Array<PropertyDict> | null} separated_keys - 분리된 속성들
   * @property {Array<UnitDict> | null} unit - 단위
   */

  constructor(keys, separated_keys = null, unit = null) {
    /**
     * @param {Array<PropertyDict>} keys - 헤더의 행/열 요소에 포함된 속성들
     * @param {Array<PropertyDict> | null} separated_keys
     * @param {Array<UnitDict> | null} unit
     */
    this.keys = keys;
    this.separated_keys = separated_keys;
    this.unit = unit;
  }

  static from_dict(json_dict, text_to_property, text_to_unit) {
    const keys = json_dict["keys"].map(
      (key) => text_to_property[key.toLowerCase()]
    );
    let separated_keys = null;
    if (json_dict["separated_keys"]) {
      separated_keys = json_dict["separated_keys"].map(
        (key) => text_to_property[key]
      );
    }
    let unit = null;
    if (json_dict["unit"]) {
      unit = json_dict["unit"].map((unit) => text_to_unit[unit]);
    }
    return new HeaderItem(keys, separated_keys, unit);
  }
}

export class Relation11 {
  // id: number
  // keys: Set<PropertyDict>
  // key_cell: SheetCell
  // value_cell: SheetCell
  // cells: Array<SheetCell>
  // cell_ids: Set<number>
  // unit: Set<UnitDict> | null
  // boundary_lines: Array<SheetLine>
  // parent_keys: Set<PropertyDict>

  constructor(id, keys, key_cell, value_cell, unit = null) {
    this.id = id;
    this.keys = keys; // Set 자료형
    this.key_cell = key_cell;
    this.value_cell = value_cell;
    this.cells = [key_cell, value_cell];
    this.cell_ids = new Set([key_cell.id, value_cell.id]);
    this.unit = unit;

    this.boundary_lines = [];
    for (const cell of this.cells) {
      for (const line of cell.lines) {
        for (const linked_cell_id of Object.values(line.linked_cells)) {
          if (!this.cell_ids.has(linked_cell_id)) {
            this.boundary_lines.push(line);
            break;
          }
        }
      }
    }
  }

  /**
   * Creates a Relation11 instance from a dictionary.
   *
   * @param {Object} json_dict
   * @param {Object<string, PropertyDict>} text_to_property -
   * @param {Object<string, UnitDict>} text_to_unit -
   * @param {Object<string, SheetCell|SheetTable|SheetText|SemanticText>} all_obj -
   */
  static from_dict(json_dict, text_to_property, text_to_unit, all_obj) {
    const id = json_dict["id"];
    const keys = new Set(
      json_dict["keys"].map((key) => text_to_property[key.toLowerCase()])
    );
    const key_cell = all_obj[json_dict["key cell"]];
    const value_cell = all_obj[json_dict["value cell"]];
    let unit = null;
    if (json_dict["unit"]) {
      unit = new Set(json_dict["unit"].map((unit) => text_to_unit[unit]));
    }
    return new Relation11(id, keys, key_cell, value_cell, unit);
  }
}

export class Relation1D {
  // id: number
  // keys: Set<PropertyDict>
  // type: HeaderType
  // header: Array<HeaderItem>
  // values: Array<Array<SheetCell>> // [row][col]
  // header_cells: Array<SheetCell>
  // value_cells: Array<SheetCell>
  // cells: Array<SheetCell>
  // cell_ids: Set<number>
  // boundary_lines: Array<SheetLine>

  constructor(id, keys, header_type, header, values, header_cells) {
    this.id = id;
    this.keys = keys; // Set 자료형
    this.type = header_type;
    this.header = header;
    this.values = values;

    this.value_cells = [];
    for (const row of values) {
      this.value_cells.push(...row); // 파이썬 extend와 동일하게 배열 요소를 추가
    }

    this.header_cells = header_cells;
    this.cells = [...this.value_cells, ...this.header_cells];
    this.cell_ids = new Set(this.cells.map((cell) => cell.id));

    this.boundary_lines = [];
    for (const cell of this.cells) {
      for (const line of cell.lines) {
        for (const linked_cell_id of Object.values(line.linked_cells)) {
          if (!this.cell_ids.has(linked_cell_id)) {
            this.boundary_lines.push(line);
            break;
          }
        }
      }
    }
  }

  static from_dict(json_dict, text_to_property, text_to_unit, all_obj) {
    const id = json_dict["id"];
    const keys = new Set(
      json_dict["keys"].map((key) => text_to_property[key.toLowerCase()])
    );
    const header_type = HeaderType[json_dict["header type"]];
    const header = json_dict["header"].map((header_item) =>
      HeaderItem.from_dict(header_item, text_to_property, text_to_unit)
    );
    const values = json_dict["values"].map((row) =>
      row.map((cell_id) => all_obj[cell_id])
    );
    const header_cells = json_dict["header cells"].map(
      (cell_id) => all_obj[cell_id]
    );
    return new Relation1D(id, keys, header_type, header, values, header_cells);
  }
}

export class Relation2D {
  /**
   * @property {number} id - Unique identifier for the relation
   * @property {Array<HeaderItem>} header_col - Column headers
   * @property {Array<HeaderItem>} header_row - Row headers
   * @property {Array<Array<Array<SheetCell>>>} values - 2D array of sheet cells [row][col][cells]
   * @property {Array<SheetCell>} col_header_cells - Column header cells
   * @property {Array<SheetCell>} row_header_cells - Row header cells
   * @property {Array<SheetCell>} value_cells - All value cells
   * @property {Array<SheetCell>} cells - All cells including header and value cells
   * @property {Set<number>} cell_ids - Set of all cell IDs
   * @property {Array<SheetLine>} boundary_lines - Boundary lines of the relation
   * @property {RelationTitle|null} parent - Parent relation title, if any
   */

  constructor(
    id,
    header_col,
    header_row,
    values,
    col_header_cells,
    row_header_cells
  ) {
    this.id = id;
    this.header_col = header_col;
    this.header_row = header_row;
    this.values = values;
    this.col_header_cells = col_header_cells;
    this.row_header_cells = row_header_cells;

    this.value_cells = [];
    for (const row of values) {
      for (const cells of row) {
        this.value_cells.push(...cells); // 파이썬의 extend와 동일하게 배열 요소를 추가
      }
    }

    this.cells = [
      ...col_header_cells,
      ...row_header_cells,
      ...this.value_cells,
    ];
    this.cell_ids = new Set(this.cells.map((cell) => cell.id));

    this.boundary_lines = [];
    for (const cell of this.cells) {
      for (const line of cell.lines) {
        for (const linked_cell_id of Object.values(line.linked_cells)) {
          if (!this.cell_ids.has(linked_cell_id)) {
            this.boundary_lines.push(line);
            break;
          }
        }
      }
    }

    this.parent = null; // 기본값은 null
  }

  static from_dict(json_dict, text_to_property, text_to_unit, all_obj) {
    const id = json_dict["id"];
    const header_col = json_dict["column header"].map((header_item) =>
      HeaderItem.from_dict(header_item, text_to_property, text_to_unit)
    );
    const header_row = json_dict["row header"].map((header_item) =>
      HeaderItem.from_dict(header_item, text_to_property, text_to_unit)
    );
    const values = json_dict["values"].map((col) =>
      col.map((row) => row.map((cell_id) => all_obj[cell_id]))
    );
    const col_header_cells = json_dict["column header cells"].map(
      (cell_id) => all_obj[cell_id]
    );
    const row_header_cells = json_dict["row header cells"].map(
      (cell_id) => all_obj[cell_id]
    );
    return new Relation2D(
      id,
      header_col,
      header_row,
      values,
      col_header_cells,
      row_header_cells
    );
  }
}

const ID_PLACE_TABLE = [9, 10];
const ID_PLACE_CELL = [5, 8];
const ID_PLACE_CLASSIFIER = [4, 4];
const ID_PLACE_TEXT = [1, 3];

export class SheetInfoManager {
  /**
   * @property {string} project_folder
   * @property {ProjectInfo} project_info
   * @property {Array<PropertyDict>} properties
   * @property {Object<string, PropertyDict>} text_to_property
   * @property {Array<UnitDict>} units
   * @property {Object<string, UnitDict>} text_to_unit
   * @property {Array<TextPattern>} annotation_patterns
   * @property {Array<TextPattern>} separator_patterns
   * @property {Object<number, RelationDictTitle|RelationDict2D|RelationDict1D|RelationDict11>} relation_dictionary
   * @property {Object<number, SheetPage>} sheet_pages
   * @property {Object<number, SheetLine>} sheet_lines
   */

  /** @param {string} project_folder - json 파일이 위치한 경로 */
  constructor(project_folder) {
    this.project_folder = project_folder;
  }

  async load_sheet_info() {
    const sheet_info_json = await this.#load_sheet_info_json(
      this.project_folder
    );
    this.#load_project_info(sheet_info_json["project info"]);
    this.#load_dictionary_info(sheet_info_json["dictionary info"]);
    this.#load_object_info_obj(sheet_info_json["object info"]);
  }

  async #load_sheet_info_json(project_folder) {
    const response = await axios.get(project_folder, {
      withCredentials: true,
    });

    return response.data;
  }

  #load_project_info(project_info_json) {
    this.project_info = new ProjectInfo(
      project_info_json["created date"],
      project_info_json["modified date"],
      project_info_json["description"]
    );
  }

  #load_dictionary_info(dictionary_info_json) {
    this.properties = [];
    this.units = [];
    this.relation_dictionary = {};

    if (dictionary_info_json["properties"]) {
      for (let property_node of dictionary_info_json["properties"]) {
        this.properties.push(
          new PropertyDict(
            property_node["representative"],
            property_node["description"],
            property_node["synonyms"]
          )
        );
      }
    }

    if (dictionary_info_json["units"]) {
      for (let unit_node of dictionary_info_json["units"]) {
        this.units.push(
          new UnitDict(
            unit_node["unit"],
            unit_node["description"],
            unit_node["synonyms"]
          )
        );
      }
    }

    if (dictionary_info_json["annotation patterns"]) {
      this.annotation_patterns = [];
      for (let annotation_node of dictionary_info_json["annotation patterns"]) {
        this.annotation_patterns.push(
          new TextPattern(annotation_node["regex"], annotation_node["examples"])
        );
      }
    }

    if (dictionary_info_json["separator patterns"]) {
      this.separator_patterns = [];
      for (let separator_node of dictionary_info_json["separator patterns"]) {
        this.separator_patterns.push(
          new TextPattern(separator_node["regex"], separator_node["examples"])
        );
      }
    }

    this.#match_text_to_dictionary();

    if ("relation dictionary" in dictionary_info_json) {
      let id = 1;
      let stack = [];

      for (const relation_dict_json of dictionary_info_json[
        "relation dictionary"
      ]) {
        stack.push([relation_dict_json, null]);

        while (stack.length) {
          const [relation_dict_json, parent] = stack.pop();
          let relation_dict_obj;

          if (relation_dict_json["class"] === "1:1") {
            const keys = new Set(
              relation_dict_json["keys"].map(
                (key_str) => this.text_to_property[key_str.toLowerCase()]
              )
            );
            relation_dict_obj = new RelationDict11(id, parent, keys);
          } else if (relation_dict_json["class"] === "1D") {
            const keys = new Set(
              relation_dict_json["headers"].map(
                (key_str) => this.text_to_property[key_str.toLowerCase()]
              )
            );
            const type = HeaderType[relation_dict_json["header type"]];
            relation_dict_obj = new RelationDict1D(id, parent, keys, type);
          } else if (relation_dict_json["class"] === "2D") {
            const column_keys = new Set(
              relation_dict_json["column headers"].map(
                (key_str) => this.text_to_property[key_str.toLowerCase()]
              )
            );
            const row_keys = new Set(
              relation_dict_json["row headers"].map(
                (key_str) => this.text_to_property[key_str.toLowerCase()]
              )
            );
            relation_dict_obj = new RelationDict2D(
              id,
              parent,
              column_keys,
              row_keys
            );
          } else if (relation_dict_json["class"] === "title") {
            const keys = new Set(
              relation_dict_json["keys"].map(
                (key_str) => this.text_to_property[key_str.toLowerCase()]
              )
            );
            const type = TitleType[relation_dict_json["type"]];
            relation_dict_obj = new RelationDictTitle(id, parent, keys, type);

            // title 관계는 자식이 존재할 수 있음. 자식이 존재하는 경우 stack에 추가하여 재귀적으로 동작
            for (const sub_relation_dict_json of relation_dict_json[
              "sub relations"
            ]) {
              stack.push([sub_relation_dict_json, relation_dict_obj]);
            }
          } else {
            throw new Error(
              `입력 데이터 에러: 'relation dictionary'-'${relation_dict_json["class"]}'`
            );
          }

          if (parent !== null) {
            parent.sub_relations.push(relation_dict_obj);
          }

          this.relation_dictionary[relation_dict_obj.id] = relation_dict_obj;
          id += 1;
        }
      }
    }
  }

  #match_text_to_dictionary() {
    // text 입력하면 바로 속성 객체 찾을 수 있도록
    this.text_to_property = {};
    for (const _property of this.properties) {
      this.text_to_property[_property.representative.toLowerCase()] = _property;
      for (const synonym of _property.synonyms) {
        this.text_to_property[synonym.toLowerCase()] = _property;
      }
    }

    // text 입력하면 바로 단위 객체 찾을 수 있도록
    this.text_to_unit = {};
    for (const unit of this.units) {
      this.text_to_unit[unit.text] = unit;
    }
  }

  /**
   * @param {string} object_info_json - json 문자열 데이터
   * @return {Object<number, SheetPage>} - sheet page 객체들
   */
  #load_object_info_obj(object_info_json) {
    let sheet_pages = {};

    for (let sheet_page_node of object_info_json["sheet page list"]) {
      let page_num = sheet_page_node["page num"];
      let is_target = sheet_page_node["is target"];
      let width = sheet_page_node["width"];
      let height = sheet_page_node["height"];
      let sheet_page = new SheetPage(
        page_num,
        is_target,
        width,
        height,
        this.project_folder
      );
      sheet_pages[page_num] = sheet_page;

      for (const sheet_line_node of sheet_page_node["lines"]) {
        const sheet_line = SheetLine.from_json(sheet_line_node);
        sheet_page.lines.set(sheet_line.id, sheet_line);
      }

      // 객체 생성===
      for (let sheet_obj_node of sheet_page_node["all objs"]) {
        if (sheet_obj_node["class"] === "sheet table") {
          let sheet_table = new SheetTable(
            sheet_obj_node["id"],
            sheet_obj_node["corner points"]
          );
          sheet_page.all_objs[sheet_table.id] = sheet_table;
        } else if (sheet_obj_node["class"] === "sheet cell") {
          const lines = sheet_obj_node["lines"].map((line_id) =>
            sheet_page.lines.get(line_id)
          );
          let sheet_cell = new SheetCell(
            sheet_obj_node["id"],
            sheet_obj_node["corner points"],
            lines
          );
          sheet_page.all_objs[sheet_cell.id] = sheet_cell;
        } else if (sheet_obj_node["class"] === "sheet text") {
          let sheet_text = new SheetText(
            sheet_obj_node["id"],
            sheet_obj_node["corner points"],
            sheet_obj_node["text"]
          );
          sheet_page.all_objs[sheet_text.id] = sheet_text;
        } else if (sheet_obj_node["class"] === "semantic text") {
          let semantic_text = new SemanticText(
            sheet_obj_node["id"],
            sheet_obj_node["corner points"],
            sheet_obj_node["text"],
            sheet_obj_node["type"],
            sheet_obj_node["key"],
            sheet_obj_node["unit"]
          );
          sheet_page.all_objs[semantic_text.id] = semantic_text;
        }
      }
      // ===객체 생성

      // 객체 연결===
      for (let inner_table_id of sheet_page_node["inner tables"]) {
        sheet_page.inner_tables.push(sheet_page.all_objs[inner_table_id]);
      }

      for (let inner_text_id of sheet_page_node["inner texts"]) {
        sheet_page.inner_texts.push(sheet_page.all_objs[inner_text_id]);
      }

      for (let sheet_obj_node of sheet_page_node["all objs"]) {
        let id = sheet_obj_node["id"];

        if (sheet_obj_node["class"] === "sheet table") {
          let sheet_table = sheet_page.all_objs[id];

          // outer cell 연결
          let outer_cell_id = sheet_obj_node["outer cell"];
          if (outer_cell_id) {
            sheet_table.outer_cell = sheet_page.all_objs[outer_cell_id];
          } else {
            sheet_table.outer_cell = null;
          }

          // inner cell 연결
          for (let inner_cell_id of sheet_obj_node["inner cells"]) {
            sheet_table.inner_cells.push(sheet_page.all_objs[inner_cell_id]);
          }
        } else if (sheet_obj_node["class"] === "sheet cell") {
          let sheet_cell = sheet_page.all_objs[id];

          // parent 연결
          let parent_id = sheet_obj_node["parent"];
          sheet_cell.parent = sheet_page.all_objs[parent_id];

          // morp link cell 연결
          for (let [direction, link_cell_ids] of Object.entries(
            sheet_obj_node["morp link cells"]
          )) {
            sheet_cell.morp_link_cell[direction] = [];
            for (let link_cell_id of link_cell_ids) {
              sheet_cell.morp_link_cell[direction].push(
                sheet_page.all_objs[link_cell_id]
              );
            }
          }

          // inner text 연결
          for (let inner_text_id of sheet_obj_node["inner texts"]) {
            sheet_cell.inner_texts.push(sheet_page.all_objs[inner_text_id]);
          }

          // inner table 연결
          for (let inner_table_id of sheet_obj_node["inner tables"]) {
            sheet_cell.inner_tables.push(sheet_page.all_objs[inner_table_id]);
          }
        } else if (sheet_obj_node["class"] === "sheet text") {
          let sheet_text = sheet_page.all_objs[id];

          // parent 연결
          let parent_id = sheet_obj_node["parent"];
          if (parent_id) {
            sheet_text.parent = sheet_page.all_objs[parent_id];
          } else {
            sheet_text.parent = null;
          }
        } else if (sheet_obj_node["class"] === "semantic text") {
          let semantic_text = sheet_page.all_objs[id];

          // parent 연결
          let parent_id = sheet_obj_node["parent"];
          semantic_text.parent = sheet_page.all_objs[parent_id];
          semantic_text.parent.inner_semantic_texts.push(semantic_text);

          if (semantic_text.type === "key") {
            semantic_text.parent.properties.concat(semantic_text.properties);
          } else if (semantic_text.type === "separator") {
            semantic_text.parent.separated_properties.concat(
              semantic_text.properties
            );
          } else if (semantic_text.type === "unit") {
            semantic_text.parent.units.push(semantic_text.unit);
          } else if (semantic_text.type === "value") {
            semantic_text.parent.values.push(semantic_text.text);
          }
        }
      }
      // ===객체 연결

      for (const relation_node of sheet_page_node["relation tree"]) {
        let relation_obj;
        if (relation_node["class"] === "1:1") {
          relation_obj = Relation11.from_dict(
            relation_node,
            this.text_to_property,
            this.text_to_unit,
            sheet_page.all_objs
          );
        } else if (relation_node["class"] === "1D") {
          relation_obj = Relation1D.from_dict(
            relation_node,
            this.text_to_property,
            this.text_to_unit,
            sheet_page.all_objs
          );
        } else if (relation_node["class"] === "2D") {
          relation_obj = Relation2D.from_dict(
            relation_node,
            this.text_to_property,
            this.text_to_unit,
            sheet_page.all_objs
          );
        } else {
          // relation_node["class"] === "title"
          relation_obj = RelationTitle.from_dict(
            relation_node,
            this.text_to_property,
            this.text_to_unit,
            sheet_page.all_objs,
            sheet_page.relation_list
          );
        }
        sheet_page.relation_tree.push(relation_obj);
        sheet_page.relation_list.set(relation_obj.id, relation_obj);
      }
    }
    this.sheet_pages = sheet_pages;
  }

  make_obj_map() {
    // console.log("make_obj_map");
    for (let sheet_page of Object.values(this.sheet_pages)) {
      // obj_map 초기화
      sheet_page.obj_map = new Array(sheet_page.width);
      for (let i = 0; i < sheet_page.width; i++) {
        sheet_page.obj_map[i] = new Array(sheet_page.height).fill(0);
      }

      // obj_map에 객체 id 저장
      for (let sheet_table of sheet_page.inner_tables) {
        for (let sheet_cell of sheet_table.inner_cells) {
          // console.log("assign_obj_id_to_map");
          this.#assign_obj_id_to_map(sheet_page.obj_map, sheet_cell);

          for (let sheet_text of sheet_cell.inner_texts) {
            this.#assign_obj_id_to_map(sheet_page.obj_map, sheet_text);
          }
        }
      }
    }
  }

  #assign_obj_id_to_map(obj_map, sheet_obj) {
    // 사각형이면
    if (sheet_obj.conner_points.length === 4) {
      let [x1, y1] = sheet_obj.conner_points[0];
      let [x2, y2] = sheet_obj.conner_points[2];

      for (let x = x1; x <= x2; x++) {
        for (let y = y1; y <= y2; y++) {
          obj_map[x][y] = sheet_obj.id;
        }
      }
    }
    // 사각형이 아니면
    else {
      this.#make_boundary(obj_map, sheet_obj.conner_points, sheet_obj.id); // 경계를 그리고
      let point = this.#get_random_point_in_polygon(sheet_obj.conner_points); // 경계 안의 한 점을 선택하고
      this.#fill_boundary(obj_map, point, sheet_obj.id); // 그 점을 시작으로 경계 내부를 채움
    }
  }

  #fill_boundary(obj_map, point, obj_id) {
    let stack = [point];

    while (stack.length > 0) {
      let [x, y] = stack.pop();

      // 범위를 벗어나거나, 이미 채워진 경우, 또는 경계를 만난 경우 종료
      if (
        x < 0 ||
        x >= obj_map.length ||
        y < 0 ||
        y >= obj_map[0].length ||
        obj_map[x][y] !== 0 ||
        obj_map[x][y] === obj_id
      ) {
        continue;
      }

      // 현재 위치를 채움
      obj_map[x][y] = obj_id;

      // 상하좌우로 확장
      stack.push([x - 1, y]); // 왼쪽
      stack.push([x + 1, y]); // 오른쪽
      stack.push([x, y - 1]); // 위
      stack.push([x, y + 1]); // 아래
    }
  }

  #make_boundary(obj_map, vertices, obj_id) {
    for (let i = 0; i < vertices.length; i++) {
      let start = vertices[i];
      let end = vertices[(i + 1) % vertices.length]; // 다음 꼭짓점, 마지막 꼭짓점의 경우 첫 번째 꼭짓점으로 연결

      // 선분의 시작점과 끝점 사이의 모든 점을 계산
      let points = this.#get_line_points(start, end);

      // 각 점에 대해 obj_map에 obj_id를 할당
      for (let point of points) {
        let [x, y] = point;
        obj_map[x][y] = obj_id;
      }
    }
  }

  #get_line_points(start, end) {
    let points = [];

    let [x1, y1] = start;
    let [x2, y2] = end;

    // 수평선인 경우
    if (y1 === y2) {
      let minX = Math.min(x1, x2);
      let maxX = Math.max(x1, x2);
      for (let x = minX; x <= maxX; x++) {
        points.push([x, y1]);
      }
    }
    // 수직선인 경우
    else if (x1 === x2) {
      let minY = Math.min(y1, y2);
      let maxY = Math.max(y1, y2);
      for (let y = minY; y <= maxY; y++) {
        points.push([x1, y]);
      }
    }

    return points;
  }

  #is_point_in_polygon(x, y, vertices) {
    // 'ray casting' 알고리즘을 사용하여 점이 다각형 내부에 있는지 확인합니다.
    // 이 알고리즘은 점에서 임의의 방향으로 '선'을 그어, 그 선이 다각형의 변과 몇 번 교차하는지 세는 방법입니다.

    let inside = false;

    for (let i = 0, j = vertices.length - 1; i < vertices.length; j = i++) {
      let xi = vertices[i][0],
        yi = vertices[i][1];
      let xj = vertices[j][0],
        yj = vertices[j][1];

      let intersect =
        yi > y !== yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
      if (intersect) inside = !inside;
    }

    return inside;
  }

  #get_random_point_in_polygon(vertices) {
    // 다각형의 바운딩 박스
    let minX = Math.min(...vertices.map((v) => v[0]));
    let maxX = Math.max(...vertices.map((v) => v[0]));
    let minY = Math.min(...vertices.map((v) => v[1]));
    let maxY = Math.max(...vertices.map((v) => v[1]));

    // 바운딩 박스에서 무작위로 점 하나 선택
    let x, y;
    do {
      x = Math.floor(Math.random() * (maxX - minX + 1)) + minX;
      y = Math.floor(Math.random() * (maxY - minY + 1)) + minY;
    } while (!this.#is_point_in_polygon(x, y, vertices));

    return [x, y];
  }

  get_sheet_obj_by_id(page_num, obj_id) {
    return this.sheet_pages[page_num].all_objs[obj_id];
  }

  get_sheet_page(page_num) {
    return this.sheet_pages[page_num];
  }

  /**
   * @param page_num {number} - 페이지 번호
   * @param type {string} - "cell"|"table"|"text"|"semantic text" 가져오길 원하는 객체 타입
   * @param x {number} - 마우스 클릭 x 좌표
   * @param y {number} - 마우스 클릭 y 좌표
   * @return {SheetTable|SheetCell|SheetText|SemanticText|null} - 좌표에 대응되는 객체. 없으면 null 반환
   */
  get_sheet_obj_by_point(page_num, type, x, y) {
    let obj_map = this.sheet_pages[page_num].obj_map;
    let obj_id = obj_map[x][y];
    if (obj_id === 0) {
      return null;
    }

    if (type === "cell") {
      obj_id = this.#separate_id(obj_id, "cell");
      if (obj_id === null) {
        return null;
      }
      return this.get_sheet_obj_by_id(page_num, obj_id);
    } else if (type === "table") {
      obj_id = this.#separate_id(obj_id, "table");
      if (obj_id === null) {
        return null;
      }
      return this.get_sheet_obj_by_id(page_num, obj_id);
    } else if (type === "text") {
      obj_id = this.#separate_id(obj_id, "text");
      if (obj_id === null) {
        return null;
      }
      return this.get_sheet_obj_by_id(page_num, obj_id);
    } else if (type === "semantic text") {
      obj_id = this.#separate_id(obj_id, "cell");
      if (obj_id === null) {
        return null;
      }
      let sheet_cell = this.get_sheet_obj_by_id(page_num, obj_id);
      let selected_semantic_text = null;
      for (let semantic_text of sheet_cell.inner_semantic_texts) {
        if (this.#is_point_in_polygon(x, y, semantic_text.conner_points)) {
          selected_semantic_text = semantic_text;
          break;
        }
      }
      return selected_semantic_text;
    } else {
      return null;
    }
  }

  #separate_id(obj_id, obj_class) {
    let table_id = this.#extract_digits(obj_id, ID_PLACE_TABLE);
    let cell_id = this.#extract_digits(obj_id, ID_PLACE_CELL);
    let classifier = this.#extract_digits(obj_id, ID_PLACE_CLASSIFIER);
    let text_id = this.#extract_digits(obj_id, ID_PLACE_TEXT);

    if (obj_class === "table") {
      if (table_id !== 0) {
        return table_id * Math.pow(10, ID_PLACE_TABLE[0] - 1);
      }
    } else if (obj_class === "cell") {
      if (cell_id !== 0) {
        return (
          table_id * Math.pow(10, ID_PLACE_TABLE[0] - 1) +
          cell_id * Math.pow(10, ID_PLACE_CELL[0] - 1)
        );
      }
    } else if (obj_class === "text") {
      if (text_id !== 0) {
        return (
          table_id * Math.pow(10, ID_PLACE_TABLE[0] - 1) +
          cell_id * Math.pow(10, ID_PLACE_CELL[0] - 1) +
          classifier * Math.pow(10, ID_PLACE_CLASSIFIER[0] - 1) +
          text_id
        );
      }
    }
    return null;
  }

  #extract_digits(num, place) {
    let str_num = num.toString();
    let start_index = str_num.length - place[1];
    let end_index = str_num.length - place[0] + 1;
    return parseInt(str_num.substring(start_index, end_index));
  }
}
