import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid, CssBaseline, Box, Avatar, Typography, FormControlLabel, Checkbox, Button } from '@mui/material';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import {privatePolicy, servicePolicy} from './Policy';

export default function SignPolicy() {
  const navigate = useNavigate();

  // 체크박스 상태 관리
  const [termsValue, setTermsValue] = useState('disagree');
  const [privacyValue, setPrivacyValue] = useState('disagree');

  // 이용 약관 서비스 체크박스 핸들러
  const handleTermsChange = (value) => {
    if (termsValue !== value) {
      setTermsValue(value); // 다른 value일 경우 상태 업데이트
    }
  };

  // 개인정보처리 방침 체크박스 핸들러
  const handlePrivacyChange = (value) => {
    if (privacyValue !== value) {
      setPrivacyValue(value); // 다른 value일 경우 상태 업데이트
    }
  };

  // 다음 버튼 활성화 상태
  const isNextButtonEnabled = termsValue === 'agree' && privacyValue === 'agree';

  return (
    <Grid item>
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          mx: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
          <PersonAddAlt1Icon />
        </Avatar>
        <Typography component="h1" variant="h5">
          회원가입
        </Typography>
        <Box component="form" noValidate sx={{ mt: 1, maxWidth: '60ch' }}>
          {/* 이용 약관 서비스 */}
          <Typography variant="h6" sx={{ mt: 2 }}>
            이용 약관 서비스
          </Typography>
          <Box
            sx={{
              border: 1,
              borderColor: 'divider',
              borderRadius: 1,
              p: 2,
              maxHeight: '15vh',
              overflow: 'auto',
            }}
          >
            <Typography variant="body2" style={{ whiteSpace: 'pre-wrap' }}>{servicePolicy}</Typography>
          </Box>
          <FormControlLabel
            control={
              <Checkbox
                checked={termsValue === 'disagree'}
                onChange={() => handleTermsChange('disagree')}
              />
            }
            label="동의하지 않음"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={termsValue === 'agree'}
                onChange={() => handleTermsChange('agree')}
              />
            }
            label="동의함"
          />

          {/* 개인정보처리 방침 */}
          <Typography variant="h6" sx={{ mt: 2 }}>
            개인정보처리 방침
          </Typography>
          <Box
            sx={{
              border: 1,
              borderColor: 'divider',
              borderRadius: 1,
              p: 2,
              maxHeight: '15vh',
              overflow: 'auto',
            }}
          >
            <Typography variant="body2" style={{ whiteSpace: 'pre-wrap' }}>{privatePolicy}</Typography>
          </Box>
          <FormControlLabel
            control={
              <Checkbox
                checked={privacyValue === 'disagree'}
                onChange={() => handlePrivacyChange('disagree')}
              />
            }
            label="동의하지 않음"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={privacyValue === 'agree'}
                onChange={() => handlePrivacyChange('agree')}
              />
            }
            label="동의함"
          />

          {/* 다음 버튼 */}
          <Button
            onClick={() => {
              navigate('/signpolicy/signup');
            }}
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            disabled={!isNextButtonEnabled}
          >
            다음
          </Button>
          <Grid item>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
              <Button onClick={() => { navigate('/') }} size="small">
                되돌아가기
              </Button>
            </Box>
          </Grid>
        </Box>
      </Box>
    </Grid>
  );
}
