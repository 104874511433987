import { Box, Button, Collapse, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, styled } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setCrossCPSentenceId, setCrossCompareCategory, setCrossSTSentenceId, } from "../../../actions";

export default function DocVisualCrossVisualTable() {

  const dispatch = useDispatch();

  const crossValidationVisual = useSelector(state => state.crossValidationVisual);
  const crossDocument = useSelector(state => state.crossDocument);
  const crossCompareCategory = useSelector(state => state.crossCompareCategory);

  //eslint-disable-next-line
  const [index, setIndex] = useState();
  const [openIndex, setOpenIndex] = useState(-1);
  const [selectedRow, setSelectedRow] = useState(null);

  const [finalSentenceIds, setFinalSentenceIds] = useState([]);
  const [finalSentenceSimilarity, setFinalSentenceSimilarity] = useState([]);
  const [finalTableData, setFinalTableData] = useState([]);
  

  const handleOpen = (index) => {
    if (index < 0 || index >= crossValidationVisual.length) {
      return;
    }

    dispatch(setCrossSTSentenceId(crossValidationVisual[index].sentenceId));
    dispatch(setCrossCPSentenceId(null));
    dispatch(setCrossCompareCategory(null));

    setSelectedRow(null);
    
    setIndex(index);
    setOpenIndex(index === openIndex ? -1 : index);

    const similarPartSentences = crossValidationVisual[index].similarPartSentences;
    if (Array.isArray(similarPartSentences)) {

    } else if (typeof similarPartSentences === 'object' && similarPartSentences !== null) {

    } else {
    }

    const similarPropSentences = crossValidationVisual[index].similarPropSentences;
    if (Array.isArray(similarPropSentences)) {

    } else if (typeof similarPropSentences === 'object' && similarPropSentences !== null) {

    } else {
    }

    if (crossValidationVisual[index].value) {
      const similarValueSentences = crossValidationVisual[index].similarValueSentences;
      if (Array.isArray(similarValueSentences)) {
      } else if (typeof similarValueSentences === 'object' && similarValueSentences !== null) {
      }
    } else {
    }
    

    const similarFinalSentences = crossValidationVisual[index].similarFinalSentences;
    if (Array.isArray(similarFinalSentences)) {
      setFinalSentenceIds(similarFinalSentences.map(item => item.sentenceId));
      setFinalSentenceSimilarity(similarFinalSentences.map(item => Math.round(item.similarity * 100 * 10) / 10));
    } else {
      setFinalSentenceIds(Object.values(similarFinalSentences).map(item => item.sentenceId));
      setFinalSentenceSimilarity(Object.values(similarFinalSentences).map(item => Math.round(item.similarity * 100 * 10) / 10));
    }
  };
  // console.log('crossValidationVisual', crossValidationVisual);
  // console.log('crossDocument', crossDocument);

  useEffect(() => {
    if (finalSentenceIds.length === 0) {
      setFinalTableData([]);
    } else {
      const newTableData = finalSentenceIds.map((id, index) => {
        const matchedSentence = crossDocument.find(item => item.sentenceId === id);
        return matchedSentence ? {
          doc: matchedSentence.doc,
          page: matchedSentence.page,
          source: matchedSentence.source,
          sentenceId: matchedSentence.sentenceId,
          sentence: matchedSentence.sentence,
          part: matchedSentence.part,
          property: matchedSentence.property,
          value: matchedSentence.value,
          similarity: finalSentenceSimilarity[index],
        } : null;
      }).filter(item => item !== null);

      setFinalTableData(newTableData);
    }
    //eslint-disable-next-line
  }, [finalSentenceIds, crossDocument]);

  const handleRowClick = (row) => {
    setSelectedRow(row.sentenceId);
  };
  
  const SelectedTableRow = styled(TableRow)(({ theme, isSelected }) => ({
    backgroundColor: isSelected ? '#F0F4C3' : 'inherit',
  }));

  return (
    <TableContainer component={Paper} sx={{ borderRadius: '10px', boxShadow: 2 }}>
      <Table stickyHeader sx={{ tableLayout: 'fixed', borderCollapse: 'collapse' }}>
        <TableHead>
          <TableRow sx={{ backgroundColor: '#F5F5F5' }}>
            <TableCell align="center" colSpan={2} sx={{ py: '15px', fontWeight: 'bold', borderBottom: '2px solid #ccc' }}>문장</TableCell>
            <TableCell align="center" sx={{ py: '15px', fontWeight: 'bold', borderBottom: '2px solid #ccc' }}>검증 문장</TableCell>
            <TableCell align="center" sx={{ py: '15px', fontWeight: 'bold', borderBottom: '2px solid #ccc' }}>진행 상황</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {crossValidationVisual.map((row, index) => (
            row.similarFinalSentences.length > 0 && (
              <Fragment key={index}>
                <SelectedTableRow 
                  sx={{ 
                    '&:hover': { backgroundColor: '#f0f0f0' },
                    '& > *': { 
                      borderBottom: '1px solid #e0e0e0', 
                      wordWrap: 'break-word', 
                      whiteSpace: 'normal',
                      padding: '12px',
                      transition: 'background-color 0.3s'
                    } 
                  }} 
                  onClick={() => { handleOpen(index); }} 
                  isSelected={index === openIndex}
                >
                  <TableCell colSpan={2}> {row.sentence || 'ㅡ'} </TableCell>
                  <TableCell align="center" sx={{ 
                      fontWeight: row.similarFinalSentences.length > 0 ? 'bold' : 'normal',
                      maxWidth: '100px',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap'
                    }}
                  >
                    <Button variant="outlined">{row.similarFinalSentences.length > 0 ? <>{row.similarFinalSentences.length}문장<br />보기</> : '-'}</Button>
                  </TableCell>
                  <TableCell align="center">{'ㅡ'}</TableCell>
                </SelectedTableRow>

                <TableRow>
                  <TableCell colSpan={4} style={{ paddingBottom: 0, paddingTop: 0 }}>
                    <Collapse in={index === openIndex} timeout="auto" unmountOnExit>
                      <Box sx={{ my: 2, backgroundColor: '#fafafa', borderRadius: '8px', padding: '16px' }}>
                        <TableContainer sx={{ border: '1px solid #ccc', borderRadius: '10px', overflow: 'hidden' }}>
                          <Table size="small" sx={{ tableLayout: 'fixed' }}>
                            <TableHead>
                              <TableRow sx={{ backgroundColor: '#F5F5F5' }}>
                                <TableCell align="center" colSpan={2} sx={{ wordWrap: 'break-word', whiteSpace: 'normal', fontWeight: 'bold' }}>유사 문장</TableCell>
                                <TableCell align="center" sx={{ wordWrap: 'break-word', whiteSpace: 'normal', fontWeight: 'bold' }}>공종</TableCell>
                                <TableCell align="center" sx={{ wordWrap: 'break-word', whiteSpace: 'normal', fontWeight: 'bold' }}>유사도</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {finalTableData.map((row, index) => (
                                <TableRow
                                  key={index}
                                  onClick={() => { dispatch(setCrossCPSentenceId(row)); dispatch(setCrossCompareCategory('similarity')); handleRowClick(row); }}
                                  sx={{ 
                                    backgroundColor: selectedRow === row.sentenceId && crossCompareCategory === 'similarity' ? '#FFECB3' : 'transparent', 
                                    '&:hover': { backgroundColor: '#e0e0e0', cursor: 'pointer' } 
                                  }}
                                >
                                  <TableCell colSpan={2} sx={{ wordWrap: 'break-word', whiteSpace: 'normal' }}>{row.sentence}</TableCell>
                                  <TableCell align="center" sx={{ wordWrap: 'break-word', whiteSpace: 'normal' }}>{row.source}</TableCell>
                                  <TableCell align="center" sx={{ wordWrap: 'break-word', whiteSpace: 'normal' }}>{row.similarity}%</TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Box>
                    </Collapse>
                  </TableCell>
                </TableRow>
              </Fragment>
          )))}
        </TableBody>
      </Table>
    </TableContainer>
  );
  
};