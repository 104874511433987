import { Divider, Grid, List, ListItemButton, ListItemIcon, ListItemText, Paper, Typography } from '@mui/material';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import FilterDramaIcon from '@mui/icons-material/FilterDrama';
import AdminListAPI from '../../services/admin/AdminListAPI';
import { useDispatch } from 'react-redux';
import { setAdministratorPage } from '../../actions';



export default function AdministratorList() {

  const dispatch = useDispatch();

  const { administratorInfo } = AdminListAPI({});

  return (

    <Grid item xs={12} sm={2} md={2} sx={{ height: 'calc(100vh - 160px)' }}>
      <Paper elevation={6} sx={{height: '100%', p:2, overflow: 'auto', display: 'flex', flexDirection: 'column'}}>

        <Typography variant="h5" sx={{mb:1, whiteSpace: 'nowrap', fontSize: "clamp(1rem, 4vw, 1.5rem)"}} align="center">
          회원 관리
        </Typography>
        <Divider />

        <List dense={true}>
          <ListItemButton onClick={() => { administratorInfo(); dispatch(setAdministratorPage('info')); }}>
            <ListItemIcon>
              <SupervisedUserCircleIcon />
            </ListItemIcon>
            <ListItemText primary="회원 정보" sx={{
              '.MuiListItemText-primary': {
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }
            }} />
          </ListItemButton>
          <ListItemButton onClick={() => { administratorInfo(); dispatch(setAdministratorPage('data')); }}>
            <ListItemIcon>
              <FilterDramaIcon />
            </ListItemIcon>
            <ListItemText primary="데이터 관리" sx={{
              '.MuiListItemText-primary': {
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }
            }} />
          </ListItemButton>
        </List>

      </Paper>
    </Grid>

  )
}