import { Avatar, Badge, Button, Grid, LinearProgress, Paper, ThemeProvider, Typography, createTheme } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useSelector } from 'react-redux';
import { blue, green, grey, orange, red } from '@mui/material/colors';
import AdminListAPI from '../../services/admin/AdminListAPI';


export default function AdministratorData() {

  const administratorInfo = useSelector(state => state.administratorInfo);
  
  const { adminUserData } = AdminListAPI({});

  const classInfo = (params) => {
    const roles = params.row.roles || [];
    
    // 등급별 텍스트와 색상 매핑
    const roleSettings = {
      BASIC: { text: "B", color: grey[500] },
      STANDARD: { text: "S", color: blue[300] },
      PREMIUM: { text: "P", color: red[300] },
    };
  
    // 등급에 따른 설정
    const { text: avatarText, color: avatarColor } = 
      Object.entries(roleSettings).find(([key]) => 
        roles.some(role => role.startsWith(key))
      )?.[1] || { text: null, color: null };
  
    // Avatar 컴포넌트 생성
    const avatarComponent = (
      <Avatar sx={{ bgcolor: avatarColor, width: 30, height: 30 }}>
        {avatarText}
      </Avatar>
    );
  
    // ADMIN 역할이 있는 경우 Badge로 감싸기
    return roles.includes("ADMIN") ? (
      <Badge color="success" variant="dot">
        {avatarComponent}
      </Badge>
    ) : (
      avatarComponent
    );
  };

  const ProgressBar = ({ roles, used, total, usedText, totalText }) => {
    const percentage = total > 0 ? (used / total) * 100 : 0;
  
    // 역할에 따라 용량을 설정하는 로직
    let rolesTotal = '10MB'; // 기본값을 BASIC으로 설정
  
    if (roles && (
        roles.includes('STANDARD_DRAW') || 
        roles.includes('STANDARD_SHEET') || 
        roles.includes('STANDARD_DOC')
      )) {
      rolesTotal = '30MB'; // STANDARD 등급
    } else if (roles && (
        roles.includes('PREMIUM_DRAW') || 
        roles.includes('PREMIUM_SHEET') || 
        roles.includes('PREMIUM_DOC')
      )) {
      rolesTotal = '300MB'; // PREMIUM 등급
    }
  
    // 사용량 텍스트 설정
    const displayUsedText = 
      usedText === '-' ? '0MB' : 
      usedText == null ? '-' : 
      usedText;
  
    return (
      <div style={{ width: '100%', textAlign: 'center' }}>
        <LinearProgress variant="determinate" value={percentage} />
        <Typography variant="body2">{`${displayUsedText} / ${rolesTotal}`}</Typography>
      </div>
    );
  };
  
  

  const theme = createTheme({
    components: {
      MuiDataGrid: {
        styleOverrides: {
          root: {
            '& .MuiDataGrid-cell:focus': {
              outline: 'none',
            },
            '& .MuiDataGrid-columnHeader:focus': {
              outline: 'none',
            },
            '& .MuiDataGrid-row:hover': {
              backgroundColor: '#F5F5F5',
            },
          },
        },
      },
    },
  });

  const rows = administratorInfo.map((info, index) => ({
    index: index + 1,
    name: info.name,
    email: info.username,
    roles: info.roles,
    id: info.id,
    drawUsedVolumeB: info.drawVolume?.originalVolume !== undefined ? info.drawVolume.originalVolume : '-',
    drawTotalVolumeB: info.drawVolume?.originalUserVolume || '-', 
    drawUsedVolumeText: info.drawVolume?.volume || null, 
    drawTotalVolumeText: info.drawVolume?.userVolume || '-', 
    sheetUsedVolumeB: info.sheetVolume?.originalVolume !== undefined ? info.sheetVolume.originalVolume : '-',
    sheetTotalVolumeB: info.sheetVolume?.originalUserVolume || '-', 
    sheetUsedVolumeText: info.sheetVolume?.volume || null, 
    sheetTotalVolumeText: info.sheetVolume?.userVolume || '-', 
    docUsedVolumeB: info.docVolume?.originalVolume !== undefined ? info.docVolume.originalVolume : '-',
    docTotalVolumeB: info.docVolume?.originalUserVolume || '-', 
    docUsedVolumeText: info.docVolume?.volume || null, 
    docTotalVolumeText: info.docVolume?.userVolume || '-', 
    parsingUsed: info.parserCount?.parsingCount !== undefined ? info.parserCount.parsingCount : '-',
    parsingTotal: info.parserCount?.userParsingCount || '-',
    analysisUsed: info.analysisCount?.extractCount !== undefined ? info.analysisCount.extractCount : '-',
    analysisTotal: info.analysisCount?.userExtractCount || '-', 
  }));
  
  

  const columns = [
    { field: 'index', headerName: '번호', width: 50, },
    { field: 'class', headerName: '현 등급', width: 70, renderCell: classInfo, },
    { field: 'name', headerName: '이름', width: 150, },
    { field: 'email', headerName: '이메일', width: 200, },
    { 
      field: '*', 
      headerName: '', 
      width: 100, 
      renderCell: (params) => (
        <Button 
          variant="outlined" 
          color="primary" 
          onClick={() => {
            adminUserData(params.row.id);
          }}
        >
          조회
        </Button>
      ),
    },
    { 
      field: 'drawUsedVolumeB', 
      headerName: '도면', 
      flex: 1, 
      renderCell: (params) => (
        <ProgressBar 
          roles={params.row.roles}
          used={params.row.drawUsedVolumeB === '-' ? '-' : params.row.drawUsedVolumeB === 0 ? 0 : params.row.drawUsedVolumeB} 
          total={params.row.drawTotalVolumeB} 
          usedText={params.row.drawUsedVolumeText} 
          totalText={params.row.drawTotalVolumeText} 
        />
      ),
    },
    { 
      field: 'sheetUsedVolumeB', 
      headerName: '시트', 
      flex: 1, 
      renderCell: (params) => (
        <ProgressBar 
          roles={params.row.roles}
          used={params.row.sheetUsedVolumeB === '-' ? '-' : params.row.sheetUsedVolumeB === 0 ? 0 : params.row.sheetUsedVolumeB} 
          total={params.row.sheetTotalVolumeB} 
          usedText={params.row.sheetUsedVolumeText} 
          totalText={params.row.sheetTotalVolumeText} 
        />
      ),
    },
    { 
      field: 'docUsedVolumeB', 
      headerName: '문서', 
      flex: 1,
      renderCell: (params) => (
        <ProgressBar 
          roles={params.row.roles}
          used={params.row.docUsedVolumeB === '-' ? '-' : params.row.docUsedVolumeB === 0 ? 0 : params.row.docUsedVolumeB} 
          total={params.row.docTotalVolumeB} 
          usedText={params.row.docUsedVolumeText}
          totalText={params.row.docTotalVolumeText} 
        />
      ),
    },
    { 
      field: 'parsing', 
      headerName: 'parsing', 
      width: 80, 
      renderCell: (params) => {
        // 사용량을 확인하고 설정
        const used = params.row.parsingUsed === '-' ? '-' : params.row.parsingUsed === 0 ? 0 : params.row.parsingUsed;
    
        // 등급에 따라 총 용량을 설정하는 로직
        let total = 3; // 기본값을 BASIC으로 설정
    
        if (params.row.roles && (
            params.row.roles.includes('STANDARD_DRAW') || 
            params.row.roles.includes('STANDARD_SHEET') || 
            params.row.roles.includes('STANDARD_DOC')
          )) {
          total = 10; // STANDARD 등급
        } else if (params.row.roles && (
            params.row.roles.includes('PREMIUM_DRAW') || 
            params.row.roles.includes('PREMIUM_SHEET') || 
            params.row.roles.includes('PREMIUM_DOC')
          )) {
          total = 50; // PREMIUM 등급
        }
    
        return `${used} / ${total}`;
      },
    },
    { 
      field: 'analysis', 
      headerName: 'analysis', 
      width: 80, 
      renderCell: (params) => {
        const used = params.row.analysisUsed === '-' ? '-' : params.row.analysisUsed === 0 ? 0 : params.row.analysisUsed;
        
        // 등급에 따라 총 용량을 설정하는 로직
        let total = 1; // 기본값을 BASIC으로 설정
    
        if (params.row.roles && (
            params.row.roles.includes('STANDARD_DRAW') || 
            params.row.roles.includes('STANDARD_SHEET') || 
            params.row.roles.includes('STANDARD_DOC')
          )) {
          total = 3; // STANDARD 등급
        } else if (params.row.roles && (
            params.row.roles.includes('PREMIUM_DRAW') || 
            params.row.roles.includes('PREMIUM_SHEET') || 
            params.row.roles.includes('PREMIUM_DOC')
          )) {
          total = 15; // PREMIUM 등급
        }
    
        return `${used} / ${total}`;
      },
    },
  ];

  return (
    <Grid item xs={12} sm={10} md={10} sx={{ height: 'calc(100vh - 160px)' }}>
      <Paper elevation={6} sx={{height: '100%', position: 'relative'}}>

        <ThemeProvider theme={theme}>
          <DataGrid
            sx={{height:'calc(100vh - 170px)', '&:hover': {cursor: 'default'}}}
            rows={rows}
            columns={columns}
            disableRowSelectionOnClick
            disableAutoFocus
            disableVirtualization
          />
        </ThemeProvider>

      </Paper>
    </Grid>
  );
}