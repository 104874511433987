import React from "react";

const ObjectDataRows = (selectedObj, selectedObjectType, components) => {
  const noneStr = "Not Selected.";

  if (!selectedObj) {
    return null;
  }

  const { ObjectDataRow, ObjectDataWriteTitle, ObjectDataText } = components;
  const { inner_cells, inner_texts, text, type, properties } = selectedObj;

  switch (selectedObjectType) {
    case "table":
      return (
        <ObjectDataRow>
          <ObjectDataWriteTitle>Number of inner cells</ObjectDataWriteTitle>
          <ObjectDataText>
            {Array.isArray(inner_cells) ? inner_cells.length : noneStr}
          </ObjectDataText>
        </ObjectDataRow>
      );

    case "cell":
      return (
        <>
          <ObjectDataRow>
            <ObjectDataWriteTitle>Number of Texts</ObjectDataWriteTitle>
            <ObjectDataText>
              {Array.isArray(inner_texts) ? inner_texts.length : noneStr}
            </ObjectDataText>
          </ObjectDataRow>
          {Array.isArray(inner_texts) &&
            inner_texts.map((text, index) => (
              <ObjectDataRow key={index}>
                <ObjectDataWriteTitle>{`Value ${
                  index + 1
                }`}</ObjectDataWriteTitle>
                <ObjectDataText>{text.text || noneStr}</ObjectDataText>
              </ObjectDataRow>
            ))}
        </>
      );

    case "text":
      return (
        <ObjectDataRow>
          <ObjectDataWriteTitle>Text</ObjectDataWriteTitle>
          <ObjectDataText>{text || noneStr}</ObjectDataText>
        </ObjectDataRow>
      );

    case "semantic text":
      return (
        <>
          <ObjectDataRow>
            <ObjectDataWriteTitle>Text</ObjectDataWriteTitle>
            <ObjectDataText>{text || noneStr}</ObjectDataText>
          </ObjectDataRow>
          <ObjectDataRow>
            <ObjectDataWriteTitle>Type</ObjectDataWriteTitle>
            <ObjectDataText>{type || noneStr}</ObjectDataText>
          </ObjectDataRow>
          {type === "separator" && (
            <ObjectDataRow>
              <ObjectDataWriteTitle>Separated Properties</ObjectDataWriteTitle>
              <ObjectDataText>
                {Array.isArray(properties) ? properties.join("; ") : noneStr}
              </ObjectDataText>
            </ObjectDataRow>
          )}
        </>
      );
    default:
      return null;
  }
};

export default ObjectDataRows;
