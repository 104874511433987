import {
  Relation11,
  Relation1D,
  Relation2D,
  RelationTitle,
  SemanticText,
} from "./sheetInfoLoader";

export default function visualize_image_relation(
  sheet_info_manager,
  PAGE,
  imgSrc
) {
  let grid_item1 = document.getElementById("grid-item1");

  let img = new Image();
  let sheet_page = sheet_info_manager.sheet_pages[PAGE];
  img.src = imgSrc;
  img.onload = function () {
    const existingCanvas = grid_item1.querySelector("canvas");
    if (existingCanvas) {
      grid_item1.removeChild(existingCanvas);
    }

    let canvas = document.createElement("canvas");
    grid_item1.appendChild(canvas);
    canvas.width = img.width;
    canvas.height = img.height;

    let ctx = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0);

    HighlightSemanticTexts(ctx, sheet_page);
    HighlightRelation(ctx, sheet_page);
  };

  function HighlightSemanticTexts(ctx, sheet_page) {
    for (let obj of Object.values(sheet_page.all_objs)) {
      if (obj instanceof SemanticText) {
        let pen_color;
        if (obj.type === "key") {
          pen_color = `rgb(255, 0, 0)`;
        } else if (obj.type === "value") {
          pen_color = `rgb(0, 0, 255)`;
        } else if (obj.type === "separator") {
          pen_color = `rgb(255, 165, 0)`;
        } else if (obj.type === "unit") {
          pen_color = `rgb(0, 255, 255)`;
        } else {
          // annotation
          pen_color = `rgb(0, 255, 0)`;
        }

        HighlightSheetObj(ctx, obj, pen_color, 4);
      }
    }
  }

  /**
   * 객체 가시화
   *
   * @param {CanvasRenderingContext2D} ctx - 캔버스
   * @param {SheetText|SemanticText|SheetCell|Array<SheetCell>} sheet_obj - 강조할 객체
   * @param {String|null} pen_color - 펜 색 rgb
   * @param {number|null} thickness - 펜 두께
   * @param {number|null} gap - 외곽선과 가시화 할 선의 간격
   * @param {String|null} brush_color - 채울 색 rgba
   */
  function HighlightSheetObj(
    ctx,
    sheet_obj = null,
    pen_color = null,
    thickness = null,
    gap = null,
    brush_color = null
  ) {
    // 셀 그룹 하이라이트
    if (Array.isArray(sheet_obj)) {
      // 셀 채우기
      if (brush_color !== null) {
        for (let cell of sheet_obj) {
          DrawPolygon(ctx, cell.conner_points, null, 0, brush_color);
        }
      }

      // 외곽선 계산
      if (pen_color !== null && thickness !== null && gap !== null) {
        let cell_ids = new Set(sheet_obj.map((cell) => cell.id));

        let lines = [];
        for (let cell of sheet_obj) {
          for (let line of cell.lines) {
            for (let linked_cell_id of line.linked_cells.values()) {
              if (!cell_ids.has(linked_cell_id)) {
                lines.push(line);
                break;
              }
            }
          }
        }

        // 선 위치를 경계선 안쪽으로 조절
        lines.forEach((line) => {
          let {
            p1: [x1, y1],
            p2: [x2, y2],
          } = line;
          const trans = Math.floor(thickness / 2) + gap;

          for (const [direction, cell_id] of line.linked_cells.entries()) {
            if (cell_ids.has(cell_id)) {
              if (direction === "up") {
                y1 -= trans;
                y2 -= trans;
                x1 += trans;
                x2 -= trans;
              } else if (direction === "down") {
                y1 += trans;
                y2 += trans;
                x1 += trans;
                x2 -= trans;
              } else if (direction === "left") {
                x1 -= trans;
                x2 -= trans;
                y1 += trans;
                y2 -= trans;
              } else if (direction === "right") {
                x1 += trans;
                x2 += trans;
                y1 += trans;
                y2 -= trans;
              }
              break;
            }
          }
          DrawLine(ctx, x1, y1, x2, y2, pen_color, thickness);
        });
      }
    }
    // 단일 객체 하이라이트
    else {
      DrawPolygon(
        ctx,
        sheet_obj.conner_points,
        pen_color,
        thickness,
        brush_color
      );
    }
  }

  function DrawPolygon(ctx, points, penColor, thickness, brushColor) {
    ctx.beginPath();
    ctx.moveTo(points[0][0], points[0][1]);

    for (let i = 1; i < points.length; i++) {
      ctx.lineTo(points[i][0], points[i][1]);
    }
    ctx.closePath();

    if (brushColor) {
      ctx.fillStyle = brushColor;
      ctx.fill();
    }

    if (penColor) {
      ctx.strokeStyle = penColor;
      ctx.lineWidth = thickness;
      ctx.stroke();
    }
  }

  function DrawLine(ctx, x1, y1, x2, y2, penColor, thickness) {
    ctx.beginPath();
    ctx.moveTo(x1, y1);
    ctx.lineTo(x2, y2);
    ctx.strokeStyle = penColor;
    ctx.lineWidth = thickness;
    ctx.stroke();
  }

  /**
   * 객체 가시화
   *
   * @param {CanvasRenderingContext2D} ctx - 캔버스
   * @param {SheetPage} sheet_page - 강조할 객체
   */
  function HighlightRelation(ctx, sheet_page) {
    for (let [key, relation] of sheet_page.relation_list) {
      let key_color = `rgba(255, 0, 0, 0.1)`;
      let value_color = `rgba(0, 0, 255, 0.1)`;
      let thickness = 7;
      let gap = 4;

      if (relation instanceof Relation11) {
        let line_color = `rgb(86, 132, 68)`;
        HighlightSheetObj(ctx, relation.cells, line_color, thickness, gap);
        HighlightSheetObj(ctx, relation.key_cell, null, null, null, key_color);
        HighlightSheetObj(
          ctx,
          relation.value_cell,
          null,
          null,
          null,
          value_color
        );
      } else if (relation instanceof Relation1D) {
        let line_color = `rgb(116, 89, 174)`;
        HighlightSheetObj(ctx, relation.cells, line_color, thickness, gap);
        HighlightSheetObj(
          ctx,
          relation.header_cells,
          null,
          null,
          null,
          key_color
        );
        HighlightSheetObj(
          ctx,
          relation.value_cells,
          null,
          null,
          null,
          value_color
        );
      } else if (relation instanceof Relation2D) {
        let line_color = `rgb(0, 130, 200)`;
        HighlightSheetObj(ctx, relation.cells, line_color, thickness, gap);
        HighlightSheetObj(
          ctx,
          relation.col_header_cells,
          null,
          null,
          null,
          key_color
        );
        HighlightSheetObj(
          ctx,
          relation.row_header_cells,
          null,
          null,
          null,
          key_color
        );
        HighlightSheetObj(
          ctx,
          relation.value_cells,
          null,
          null,
          null,
          value_color
        );
      } else {
        // title
        let line_color = `rgb(234, 195, 57)`;
        let fill_color = `rgba(234, 195, 57, 0.1)`;
        HighlightSheetObj(
          ctx,
          relation.obj,
          line_color,
          thickness,
          gap,
          fill_color
        );
      }

      if (relation instanceof RelationTitle) {
        let [x1, y1, x2, y2] = relation.obj.get_box();
        let p1 = [(x1 + x2) / 2, (y1 + y2) / 2];

        for (let child of relation.children) {
          let p2;
          if (child instanceof RelationTitle) {
            let [x1, y1, x2, y2] = child.obj.get_box();
            p2 = [(x1 + x2) / 2, (y1 + y2) / 2];
          } else if (child instanceof Relation11) {
            let [x1, y1, x2, y2] = child.key_cell.get_box();
            p2 = [(x1 + x2) / 2, (y1 + y2) / 2];
          } else {
            let [x1, y1, x2, y2] = CalculateBox(child.cells);
            p2 = [(x1 + x2) / 2, (y1 + y2) / 2];
          }
          DrawLine(
            ctx,
            p1[0],
            p1[1],
            p2[0],
            p2[1],
            `rgb(255, 0, 0)`,
            thickness
          );
        }
      }
    }

    /**
     * `cells` 배열의 각 셀의 꼭짓점 좌표를 사용하여 경계 상자를 계산하는 함수
     * @param {Array<SheetCell>} cells - 셀들의 배열
     * @returns {Array<number>} - 경계 상자의 좌표 [x1, y1, x2, y2]
     */
    function CalculateBox(cells) {
      let points = [];

      // 각 셀의 꼭짓점 좌표를 수집
      for (let cell of cells) {
        points.push(...cell.conner_points); // conner_points 배열을 확장하여 points에 추가
      }

      // x와 y 좌표를 각각 분리
      let x = points.map((point) => point[0]); // x 좌표들
      let y = points.map((point) => point[1]); // y 좌표들

      // 가장 작은 x, y와 가장 큰 x, y 찾기
      let x1 = Math.min(...x);
      let y1 = Math.min(...y);
      let x2 = Math.max(...x);
      let y2 = Math.max(...y);

      // 경계 상자의 좌표 반환
      return [x1, y1, x2, y2];
    }
  }
}

export function visualize_relation_tree(sheet_page) {
  let tree_visualization = document.getElementById("grid-item2");

  // 루트 노드들을 트리로 시각화
  Object.values(sheet_page.relation_tree).forEach((relation) => {
    tree_visualization.appendChild(createTreeNode(relation));
  });
}

function createTreeNode(relation) {
  let node = document.createElement("div");
  node.className = "tree-node";

  let nodeName;

  // 노드 이름 및 속성 설정
  if (relation instanceof Relation11) {
    let properties =
      Array.from(relation.keys)
        .map((k) => k.representative)
        .slice(0, 2)
        .join(", ") + "...";
    nodeName = `<span class="bold-text">1:1 </span> <span class="light-text">속성: ${properties}</span>`;
  } else if (relation instanceof Relation1D) {
    let properties =
      Array.from(relation.keys)
        .map((k) => k.representative)
        .slice(0, 2)
        .join(", ") + "...";
    nodeName = `<span class="bold-text">1D</span><span class="light-text">헤더 방향:${relation.header_type}; 속성: ${properties}</span>`;
  } else if (relation instanceof Relation2D) {
    let properties_hori =
      Array.from(relation.header_col)
        .map((k) => k.keys[0].representative)
        .slice(0, 2)
        .join(", ") + "...";
    let properties_vert =
      Array.from(relation.header_row)
        .map((k) => k.keys[0].representative)
        .slice(0, 2)
        .join(", ") + "...";
    nodeName = `<span class="bold-text">2D</span><span class="light-text">수평 헤더: ${properties_hori}; 수직 헤더: ${properties_vert}</span>`;
  } else if (relation instanceof RelationTitle) {
    let properties =
      Array.from(relation.keys)
        .map((k) => k.representative)
        .slice(0, 2)
        .join(", ") + "...";
    nodeName = `<span class="bold-text">Title</span><span class="light-text">속성: ${properties}</span>`;
  }

  // 트리 노드 추가
  node.innerHTML = nodeName;

  // 자식 노드가 있는 경우 처리
  if (relation instanceof RelationTitle && relation.children.length > 0) {
    let childrenContainer = document.createElement("div");
    childrenContainer.className = "tree-children";
    relation.children.forEach((subRelation) => {
      childrenContainer.appendChild(createTreeNode(subRelation));
    });
    node.appendChild(childrenContainer);
  }

  return node;
}
