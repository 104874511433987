import React, { useEffect, useRef, useState } from "react";
import "./SheetVisualPage.css";
import visualize_image from "../helpers/selectObject";
import visualize_image_relation, {
  visualize_relation_tree,
} from "../helpers/selectRelation";
import { SheetInfoManager } from "../helpers/sheetInfoLoader";
import styled from "styled-components";
import { mainColors } from "../constants/index";
import { Metadata } from "../components/sheet/Metadata";
import { useLocation } from "react-router-dom";
import SheetInfoDataForm from "../components/sheet/SheetInfoDataForm";

const SheetVisualPage = () => {
  const location = useLocation();
  const { fileName } = location.state || {};

  const extratNameAndPage = (fileName) => {
    let newStr = fileName.split("/");
    let name = newStr.pop();
    let page = parseInt(name.split("_")[0], 10);

    return {
      path: newStr.join("/"),
      name: name,
      page: page,
    };
  };

  const { path, name, page } = extratNameAndPage(fileName);

  let PAGE = page;
  let imgSrc = `/proxy/sheet-image/?imageKey=${path}/${name}`;
  let projectFolder = `/proxy/sheet-data/?jsonKey=${path}/sheet_info.json`;

  const metadataRef = useRef(null);
  const [isFixed, setIsFixed] = useState(false);
  const [selectedObj, setSelectedObj] = useState(null);
  const [selectedTab, setSelectedTab] = useState(0);
  const [metadata, setMetadata] = useState({});
  const [sheetInfoManager, setSheetInfoManager] = useState(null);
  const prevSelectedTab = useRef(selectedTab);

  // 초기화 함수
  const initialize = async () => {
    const manager = new SheetInfoManager(projectFolder); // 지역 변수로 할당
    await manager.load_sheet_info(); // 비동기 로딩
    manager.make_obj_map(); // 오브젝트 맵 생성

    // 메타데이터 설정
    setMetadata(manager.project_info);
    // sheetInfoManager를 state로 설정
    setSheetInfoManager(manager);

    visualize_image(manager, PAGE, imgSrc, setSelectedObj);
  };

  useEffect(() => {
    initialize(); // 페이지 첫 로드 시 초기화
  }, []); // 빈 배열을 주면 최초 1회만 실행됨

  useEffect(() => {
    if (!sheetInfoManager) return; // sheetInfoManager가 초기화된 이후에만 실행

    // selectedTab 값이 변경될 때만 시각화 함수 호출
    if (prevSelectedTab.current !== selectedTab) {
      prevSelectedTab.current = selectedTab; // 이전 상태 업데이트

      if (selectedTab === 1) {
        visualize_image_relation(sheetInfoManager, PAGE, imgSrc); // 관계 시각화 함수 호출
        visualize_relation_tree(sheetInfoManager.sheet_pages[PAGE]);
      } else {
        visualize_image(sheetInfoManager, PAGE, imgSrc, setSelectedObj); // 기본 이미지 시각화 함수 호출
      }
    }
  }, [selectedTab, PAGE, imgSrc, sheetInfoManager]);

  useEffect(() => {
    const handleScroll = () => {
      if (metadataRef.current) {
        const boundingRect = metadataRef.current.getBoundingClientRect();

        if (boundingRect.bottom <= 64) {
          setIsFixed(true);
        } else {
          setIsFixed(false);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <SheetContainer>
      <Metadata ref={metadataRef} projectName={path} metadata={metadata} />

      <VisualContainer>
        <GridItemContainer id="sheet-image-container">
          <div id="grid-item1" className="grid-item"></div>
        </GridItemContainer>

        <SheetInfoDataForm
          isFixed={isFixed}
          selectedObj={selectedObj}
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
        ></SheetInfoDataForm>
      </VisualContainer>
    </SheetContainer>
  );
};

const SheetContainer = styled.div`
  position: relative;
  background-color: ${mainColors.BACKGROUND};
`;

const VisualContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;

const GridItemContainer = styled.div`
  place-items: center;
  padding: 5px 20px 5px;
  flex: 0 0 70%;
`;

export default SheetVisualPage;
