import {
  Box,
  Button,
  CardMedia,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { BrowserUpdatedOutlined as BrowserUpdatedOutlinedIcon } from "@mui/icons-material";
import { setModuleDownloadAlert } from "../../actions";
import { useMatch, useNavigate } from "react-router-dom";
import useModuleDown from "../../hooks/useModuleDown";
import { useState } from "react";
import UserAPI from "../../services/user/UserAPI";
import { blue } from "@mui/material/colors";

export default function Download() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { moduleDownload, cancelDownload } = useModuleDown();
  const { mypageUserInfo } = UserAPI({});

  const matchDrawDownloadPage = useMatch("/module/drawdownload");

  const moduleDownloadAlert = useSelector((state) => state.moduleDownloadAlert);
  const [progress, setProgress] = useState(0);
  const [isDownloading, setIsDownloading] = useState(false);

  const handleDownloadButton = () => {
    const adress = matchDrawDownloadPage ? "draw-module" : "sheet-module";

    setIsDownloading(true);
    moduleDownload(
      adress,
      (progress) => setProgress(progress),
      () => {
        setIsDownloading(false);
        setProgress(0);
        dispatch(setModuleDownloadAlert(false));
      },
      (error) => {
        console.error("Error downloading the file", error);
        setIsDownloading(false);
      }
    );
  };

  const handleCancelButton = () => {
    cancelDownload();
    setIsDownloading(false);
  };

  const handleCloseButton = () => {
    setIsDownloading(false);
    setProgress(0);
    dispatch(setModuleDownloadAlert(false));
  };

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleGradeCheck = async () => {
    const userInfos = await mypageUserInfo();

    if (userInfos && userInfos.roles) {
      const roles = userInfos.roles;

      if (
        roles.includes("ADMIN") ||
        roles.every(
          (role) => role.startsWith("STANDARD") || role.startsWith("PREMIUM")
        )
      ) {
        dispatch(setModuleDownloadAlert(true));
      } else {
        if (roles.every((role) => role.startsWith("BASIC"))) {
          handleClickOpen();
        }
      }
    }
  };

  return (
    <Grid>
      <Grid
        item
        md={12}
        sx={{
          position: "relative",
          height: "23vh",
          backgroundImage: "url(/module.png)",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <Typography
          variant="h4"
          component="h1"
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            color: "white",
            whitespace: "nowrap",
          }}
        >
          모듈 다운로드 및 설치
        </Typography>
      </Grid>

      <Container maxWidth="lg" sx={{ mb: 5 }}>
        <Grid container spacing={1} sx={{ my: 2 }}>
          <Grid item sm={12} md={6}>
            <Grid item sm={12} md={12}>
              <Typography variant="h4" gutterBottom>
                {matchDrawDownloadPage ? "도면 모듈" : "시트 모듈"}
              </Typography>
              <Typography variant="body1" sx={{ mt: 3, mb: 2, mx: 1 }}>
                {matchDrawDownloadPage
                  ? "AI 기술을 적용하여 이미지 형식 도면에서 객체를 인식하고, 도면의 위상을 재구성하여 구조화된 디지털 도면으로 설계 정보를 추출합니다."
                  : "설계 시트 내의 기자재 정보를 인식 및 추출하여 구조화된 형태로 변환하고, 인식된 텍스트를 셀 단위로 구분하는 시멘틱 정보를 추출합니다."}
              </Typography>
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Button
                  variant="contained"
                  sx={{ mt: 2, mb: 3, width: "30%" }}
                  onClick={handleGradeCheck}
                >
                  <BrowserUpdatedOutlinedIcon />
                  &nbsp;지금 다운로드
                </Button>
              </Box>
            </Grid>
            <Grid item sm={12} md={12}>
              <CardMedia
                component="img"
                image={`${process.env.PUBLIC_URL}/${
                  matchDrawDownloadPage
                    ? "drawdownload.png"
                    : "sheetdownload.png"
                }`}
                alt="description"
                sx={{
                  width: "100%", // 또는 원하는 픽셀 값, 예: '200px'
                  height: "auto", // 비율을 유지하려면 auto로 설정
                  maxHeight: "250px", // 최대 높이 설정
                  objectFit: "contain",
                }}
              />
            </Grid>
          </Grid>

          <Grid item sm={12} md={6}>
            <Typography variant="h5" gutterBottom>
              {matchDrawDownloadPage
                ? "도면 모듈 프로그램 사양"
                : "시트 모듈 프로그램 사양"}
            </Typography>

            <Box sx={{ ml: "3%", mt: 3, maxWidth: "95%" }}>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell align="center"></TableCell>
                      <TableCell
                        align="center"
                        sx={{ pt: 1, fontWeight: "bold", fontSize: "1.2rem" }}
                      >
                        최소 사양
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{ pt: 1, fontWeight: "bold", fontSize: "1.2rem" }}
                      >
                        권장 사양
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell
                        align="center"
                        sx={{
                          fontWeight: "bold",
                          width: "180px",
                          fontSize: "1rem",
                        }}
                      >
                        OS
                      </TableCell>
                      <TableCell align="center">Window 10/11</TableCell>
                      <TableCell align="center">Window 10/11</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        align="center"
                        sx={{
                          fontWeight: "bold",
                          width: "180px",
                          fontSize: "1rem",
                        }}
                      >
                        CPU
                      </TableCell>
                      <TableCell align="center">
                        {matchDrawDownloadPage ? "Intel I5" : "Intel I3 12100"}
                      </TableCell>
                      <TableCell align="center">
                        {matchDrawDownloadPage
                          ? "AMD Ryzen 7 5700X"
                          : "Intel I5 13600"}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        align="center"
                        sx={{
                          fontWeight: "bold",
                          width: "180px",
                          fontSize: "1rem",
                        }}
                      >
                        RAM
                      </TableCell>
                      <TableCell align="center">8Gb</TableCell>
                      <TableCell align="center">16Gb 이상</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        align="center"
                        sx={{
                          fontWeight: "bold",
                          width: "180px",
                          fontSize: "1rem",
                        }}
                      >
                        GPU
                      </TableCell>
                      <TableCell align="center">-</TableCell>
                      <TableCell align="center">
                        {matchDrawDownloadPage
                          ? "NVIDIA GeForce RTX 3060 이상"
                          : "-"}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        align="center"
                        sx={{
                          fontWeight: "bold",
                          width: "180px",
                          fontSize: "1rem",
                        }}
                      >
                        DISK
                      </TableCell>
                      <TableCell align="center">
                        {matchDrawDownloadPage ? "250Gb" : "-"}
                      </TableCell>
                      <TableCell align="center">
                        {matchDrawDownloadPage ? "250Gb 이상" : "100Gb 이상"}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        align="center"
                        sx={{
                          fontWeight: "bold",
                          width: "180px",
                          fontSize: "1rem",
                        }}
                      >
                        필수 설치 프로그램*
                      </TableCell>
                      <TableCell align="center" colSpan={2}>
                        {matchDrawDownloadPage ? ".Net 7.0 이상" : "-"}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Grid>
        </Grid>
        <Divider />
      </Container>

      <Container maxWidth="lg" sx={{ mb: 5 }}>
        <Grid
          item
          md={12}
          sx={{ height: "70vh", display: "flex", flexDirection: "column" }}
        >
          <Typography variant="h5" gutterBottom>
            이용 가이드 영상
          </Typography>
          <Box
            sx={{
              flexGrow: 1,
              display: "flex",
              justifyContent: "center",
              mb: 2,
              height: "70vh",
            }}
          >
            <iframe
              width="90%"
              height="100%"
              src={
                matchDrawDownloadPage
                  ? "https://www.youtube.com/embed/63MTldfJWkw?si=-muG0_z0u3_uLr96"
                  : "https://www.youtube.com/embed/VrH63YmMkVM?si=sNjVVI_eSU74Fo-9"
              }
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; fullscreen; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            ></iframe>
          </Box>
          <Divider />
        </Grid>
      </Container>

      <Container maxWidth="lg">
        <Typography variant="h5">참여 기관</Typography>
        <Grid container sx={{ mt: 2, mb: 3 }}>
          <Grid item sm={6} md={6}>
            <CardMedia
              component="img"
              image={`${process.env.PUBLIC_URL}/${
                matchDrawDownloadPage ? "koreauniversity.gif" : "iae.jpg"
              }`}
              alt="description"
              sx={{
                height: "60px",
                objectFit: "contain",
              }}
            />
          </Grid>

          <Grid item sm={6} md={6}>
            {matchDrawDownloadPage ? (
              <CardMedia
                component="img"
                image={`${process.env.PUBLIC_URL}/koreatechuniversity.jpg`}
                alt="description"
                sx={{
                  height: "60px",
                  objectFit: "contain",
                }}
              />
            ) : null}
          </Grid>
        </Grid>
      </Container>

      <Dialog
        open={moduleDownloadAlert}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            dispatch(setModuleDownloadAlert(false));
          }
        }}
      >
        <DialogTitle>{"모듈 다운로드"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {matchDrawDownloadPage
              ? "도면 모듈을 다운로드 하시겠습니까?"
              : "시트 모듈을 다운로드 하시겠습니까?"}
          </DialogContentText>
          <div>
            {isDownloading ? "Downloading... " : "Download File "}
            <progress value={progress} max="100"></progress>{" "}
            <span>{progress}%</span>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            // onClick={matchDrawDownloadPage ? onClickDrawModuleDownload : onClickSheetModuleDownload}
            onClick={() => handleDownloadButton()}
            disabled={isDownloading}
          >
            {isDownloading ? "다운로드중" : "다운로드"}
          </Button>
          {isDownloading ? (
            <Button onClick={() => handleCancelButton()}>취소</Button>
          ) : (
            <Button onClick={() => handleCloseButton()}>닫기</Button>
          )}
        </DialogActions>
      </Dialog>

      <Dialog open={open} onClose={handleClose} maxWidth={"md"}>
        <DialogTitle>BASIC 등급 다운로드 불가</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ whiteSpace: "pre-line" }}>
            STANDARD 등급부터 다운로드 가능합니다.
            <br />
            <Typography
              variant="subtitle2"
              sx={{ fontWeight: "bold", mt: 1, color: blue[500] }}
            >
              * dxeng@wise.co.kr으로 이름, 이메일, 전화번호 보내주시면 STANDARD
              등급으로 무료 변경해드립니다.
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              handleClose();
              navigate("/module/mypage");
            }}
          >
            등급 확인
          </Button>
          <Button onClick={handleClose}> 닫기 </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
}
