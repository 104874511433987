const mainColors = {
  POINT_BLUE: "#0070C0",
  POINT_PURPLE: "#B854AD",
  TEST_BLUE: "#21409A",
  BACKGROUND: "#F7F8FC",
};

const blockColors = {
  BORDER_GRAY: "#E0E0E0",
};

const colors = {
  LIGHT_BLUE: "#F0F2F6",
  WHITE: "#FFF",
  GRAY_100: "#F9F9F9",
  GRAY_200: "#E7E7E7",
  GRAY_500: "#8E8E8E",
  GRAY_700: "#555555",
  BLACK: "#000",
};

export { mainColors, blockColors, colors };
