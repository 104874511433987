import { Box, Container, Grid } from '@mui/material';
import AdminContent from '../components/admin/AdminContent';
import AdminList from '../components/admin/AdminList';
import AdminData from '../components/admin/AdminData';
import { useSelector } from 'react-redux';

export default function Administrator() {

  const administratorPage = useSelector(state => state.administratorPage);

  return (
    <Box sx={{my: 3}}>
      <Container maxWidth="false">
        <Grid container spacing={2}>

          <AdminList />

          {administratorPage === 'info' ? <AdminContent /> : administratorPage === 'data' ? <AdminData /> : null}

        </Grid>
      </Container>
    </Box>
  );
};