import { useDispatch } from "react-redux";
import createAxiosConfig from "../services/AxiosConfig";
import { useRef } from "react";
import axios from "axios";

const useModuleDown = () => {
  const dispatch = useDispatch();
  const axiosConfig = createAxiosConfig(dispatch);
  const cancelTokenSource = useRef(null);

  const moduleDownload = (url, onProgress, onComplete, onError) => {
    cancelTokenSource.current = axios.CancelToken.source();

    axiosConfig({
      url: `/proxy/${url}`,
      method: "GET",
      responseType: "blob",
      cancelToken: cancelTokenSource.current.token,
      onDownloadProgress: (progressEvent) => {
        const totalLength = progressEvent.total;
        if (totalLength !== null) {
          const progress = Math.round(
            (progressEvent.loaded * 100) / totalLength
          );

          if (onProgress) onProgress(progress);
        }
      },
    })
      .then((response) => {
        const disposition = response.headers["content-disposition"];
        let fileName = "downloaded-file";

        if (disposition && disposition.includes("filename=")) {
          const fileNameMatch = disposition.match(/filename="?(.+)"?/);
          if (fileNameMatch.length > 1) {
            fileName = decodeURIComponent(fileNameMatch[1]);
          }
        }

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);

        if (onComplete) onComplete();
      })
      .catch((error) => {
        if (onError) onError(error);
      });
  };

  const cancelDownload = () => {
    if (cancelTokenSource.current) {
      cancelTokenSource.current.cancel("Download canceled by the user");
    }
  };

  return { moduleDownload, cancelDownload };
};

export default useModuleDown;
