import React, { useState } from "react";
import styled from "styled-components";
import { colors, fontSize, mainColors } from "../../constants";
import RadioButtonGroup from "../common/RadioButtonGroup";
import ObjectDataRows from "./ObjectDataRows";
import HistoryTable from "./HistoryTable";

const ObjectData = ({ selectedObj }) => {
  const [selectedObjectType, setSelectedObjectType] = useState("cell");
  const radioOptions = [
    { label: "table", value: "table" },
    { label: "cell", value: "cell" },
    { label: "text", value: "text" },
    { label: "semantic text", value: "semantic text" },
  ];

  const handleRadioChange = (event) => {
    setSelectedObjectType(event.target.value);
  };

  return (
    <>
      <ObjectSelectContainer>
        <ObjectDataHeaderContainer>
          <HeaderLabel />
          <ObjectDataHeader>객체 선택</ObjectDataHeader>
        </ObjectDataHeaderContainer>
        <RadioButtonGroup
          options={radioOptions}
          selectedValue={selectedObjectType}
          onChange={handleRadioChange}
        />
      </ObjectSelectContainer>
      <ObjectInfoContainer>
        <ObjectDataHeaderContainer>
          <HeaderLabel />
          <ObjectDataHeader>선택 객체 정보</ObjectDataHeader>
        </ObjectDataHeaderContainer>
        <ObjectDataContentsContainer>
          {selectedObj ? (
            <>
              <ObjectDataRow>
                <ObjectDataWriteTitle>Object ID</ObjectDataWriteTitle>
                <ObjectDataText>{selectedObj?.id}</ObjectDataText>
              </ObjectDataRow>
              {ObjectDataRows(selectedObj, selectedObjectType, {
                ObjectDataRow,
                ObjectDataWriteTitle,
                ObjectDataText,
              })}
            </>
          ) : (
            <ObjectDataRow>
              <ObjectDataText>셀을 선택해 주세요.</ObjectDataText>
            </ObjectDataRow>
          )}
        </ObjectDataContentsContainer>
      </ObjectInfoContainer>
      {/* <ObjectHistoryContainer>
        <ObjectDataHeaderContainer>
          <HeaderLabel />
          <ObjectDataHeader>문서 변경 이력</ObjectDataHeader>
        </ObjectDataHeaderContainer>
        <HistoryTable />
      </ObjectHistoryContainer> */}
    </>
  );
};

const ObjectSelectContainer = styled.div`
  padding: 5px 10px 10px;
  border: 2px solid ${colors.GRAY_200};
  border-radius: 10px;
  background-color: ${colors.WHITE};
  margin-bottom: 10px;
`;

const ObjectInfoContainer = styled.div`
  padding: 5px 10px 10px;
  border: 2px solid ${colors.GRAY_200};
  border-radius: 10px;
  background-color: ${colors.WHITE};
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
`;

const ObjectDataHeaderContainer = styled.div`
  margin: 10px;
  gap: 6px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ObjectDataContentsContainer = styled.div`
  margin: 20px 13px 10px;
  gap: 12px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const ObjectHistoryContainer = styled.div`
  padding: 5px 10px 10px;
  border: 2px solid ${colors.GRAY_200};
  border-radius: 10px;
  background-color: ${colors.WHITE};
  display: flex;
  flex-direction: column;
  height: auto;
`;

const HeaderLabel = styled.div`
  background-color: ${mainColors.POINT_BLUE};
  height: ${parseFloat(fontSize.MEDIUM) + 4 + "px"};
  width: 6px;
  transform: translateY(1px);
`;

const ObjectDataHeader = styled.label`
  font-size: ${fontSize.MEDIUM};
  font-weight: bold;
`;

const ObjectDataRow = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
`;

const ObjectDataWriteTitle = styled.label`
  font-weight: bold;
  color: ${colors.BLACK};
  margin: 0;
  flex-basis: 90px;
  flex-shrink: 0;
`;

const ObjectDataText = styled.label`
  color: ${colors.GRAY_700};
`;

export default ObjectData;
