import {
  Box,
  Button,
  CardMedia,
  Container,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import EastIcon from "@mui/icons-material/East";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  setCurrentPath,
  setParserChangeButton,
  setRiskPage,
  setRiskVisualPage,
} from "../../../actions";

export default function RiskInfo() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <Grid container>
      <Container maxWidth="lg" sx={{ mb: 3 }}>
        <Grid container spacing={1} sx={{ my: 2 }}>
          <Grid item sm={6} md={6}>
            <Box sx={{ ml: "3%", mt: 3, maxWidth: "95%" }}>
              <CardMedia
                component="img"
                image={`${process.env.PUBLIC_URL}/${"riskguide.png"}`}
                alt="description"
                sx={{
                  width: "100%", // 또는 원하는 픽셀 값, 예: '200px'
                  height: "auto", // 비율을 유지하려면 auto로 설정
                  maxHeight: "220px", // 최대 높이 설정
                  objectFit: "contain",
                }}
              />
            </Box>
          </Grid>

          <Grid item sm={6} md={6}>
            <Grid item sm={12} md={12} sx={{ mr: 3 }}>
              <Typography variant="h4" gutterBottom>
                {"독소조항 분석"}
              </Typography>
              <Typography variant="body1" sx={{ mt: 3, mb: 2, mx: 1 }}>
                {
                  "입찰문서를 PAGE, SECTION, SENTENCE 별로 디지털 변환 및 추출합니다. 이를 통해 입찰문서 전문가를 통해 학습한 모델로 독소조항 분석을 수행합니다."
                }
              </Typography>
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Button
                  variant="contained"
                  sx={{ mt: 2, mb: 3, width: "80%" }}
                  onClick={() => {
                    dispatch(setRiskPage(false));
                    dispatch(setRiskVisualPage(false));
                    dispatch(setCurrentPath("doc/"));
                    dispatch(setParserChangeButton(false));
                    navigate("/module/risk");
                  }}
                >
                  이동하기 &nbsp;
                  <EastIcon />
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Divider />
      </Container>
    </Grid>
  );
}
