import {
  Box,
  Button,
  CardMedia,
  Container,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import EastIcon from "@mui/icons-material/East";
import UserAPI from "../../../services/user/UserAPI";

export default function CrossInfo() {
  const { onClickKist } = UserAPI({});

  return (
    <Grid container>
      <Container maxWidth="lg" sx={{ mb: 3 }}>
        <Grid container spacing={1} sx={{ my: 2 }}>
          <Grid item sm={6} md={6}>
            <Grid item sm={12} md={12} sx={{ ml: 3 }}>
              <Typography variant="h4" gutterBottom>
                {"타공종 교차검증"}
              </Typography>
              <Typography variant="body1" sx={{ mt: 3, mb: 2, mx: 1 }}>
                {
                  "문서를 인식한 후 활용 정보를 추출하고, 표준 및 상업 용어를 매핑하여 표준 용어와 타입을 정의합니다. 이를 통해 설계 사양을 추출하여 공종 별 교차검증이 가능합니다."
                }
              </Typography>
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Button
                  variant="contained"
                  sx={{ mt: 2, mb: 3, width: "80%" }}
                  onClick={onClickKist}
                >
                  이동하기 &nbsp;
                  <EastIcon />
                </Button>
              </Box>
            </Grid>
          </Grid>

          <Grid item sm={6} md={6}>
            <Box sx={{ ml: "3%", mt: 3, maxWidth: "95%" }}>
              <CardMedia
                component="img"
                image={`${process.env.PUBLIC_URL}/${"crossguide.png"}`}
                alt="description"
                sx={{
                  width: "100%", // 또는 원하는 픽셀 값, 예: '200px'
                  height: "auto", // 비율을 유지하려면 auto로 설정
                  maxHeight: "220px", // 최대 높이 설정
                  objectFit: "contain",
                }}
              />
            </Box>
          </Grid>
        </Grid>
        <Divider />
      </Container>
    </Grid>
  );
}
