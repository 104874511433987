import { SET_ADMINISTRATOR_INFO, UPDATE_ADMINISTRATOR_INFO, ADD_ADMINISTRATOR_INFO, SET_ADMINISTRATOR_PAGE } from '../../actions';

function administratorInfo(state = [], action) {
  switch (action.type) {
    case SET_ADMINISTRATOR_INFO:
      return action.administratorInfo;

    case UPDATE_ADMINISTRATOR_INFO:
      return state.map(item =>
        item.id === action.updatedInfo.id ? action.updatedInfo : item
      );

    case ADD_ADMINISTRATOR_INFO:
      return state.map(item =>
        item.id === action.newInfo.id
          ? { ...item, ...action.newInfo } // 기존 데이터 유지하면서 병합
          : item
      );
  
    default:
      return state;
  }
}

function administratorPage(state = '', action) {
  switch (action.type) {
    case SET_ADMINISTRATOR_PAGE:
      return action.administratorPage;
    default:
      return state;
  }
}

const administratorReducer = {
  administratorInfo,
  administratorPage,
};

export default administratorReducer;
